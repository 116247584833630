import { Col, Container, Row } from "react-bootstrap";
import HeroShotSearch from "../Search/HeroShotSearch";
import IncludeExclude from "../Search/IncludeExclude";
import Description from "../Search/Description";
import FAQ from "../../pages/FAQ"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function VisaDetail(props) {
  const { detail, isLogin, checkoutPackages, handleBack, addWishlist, styles, startDate, endDate, onChange } =
    props;
  return (
    <>
      <HeroShotSearch
        detail={detail}
        isLogin={isLogin}
        checkoutPackages={checkoutPackages}
        handleBack={handleBack}
        addWishlist={addWishlist}
      />
      <Container>
        <Row className={styles.contentWrap}>
          <Col lg={7} className={`${styles.detail} `}>
            <Description detail={detail} />
            <IncludeExclude detail={detail} type="visa" />
            <FAQ detail={detail} />
          </Col>
          <Col lg={4} className={`${styles.summary} offset-lg-1`}>
            <div className={styles.summaryWrap}>
              <div className={`w-100 mb-4`}>
                <div className={`${styles.titleQuantityOrder} mb-3`}>
                  Pilih Tanggal
                </div>
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

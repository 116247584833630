import { useParams } from "react-router-dom";
import styles from "./../../styles/DetailProduct.module.scss";
import { Image } from "react-bootstrap";
import location from "./../../assets/location.svg";
import { isMobileCheck } from "../../utils";

export default function Description(props) {
  const { detail } = props;
  const currUrl = useParams();
  return (
    <div id="info" className={styles.contentBox}>
      {!isMobileCheck() && (
        <>
          <div className={`${styles.heading} mt-3 mt-md-0`}>{detail?.title}</div>
          {/* {currUrl.package === "hotel" && ( */}
            <div className={styles.descriptionProduct}>
              <span>
                <Image src={location} />
              </span>
              {detail.address ? detail.address : "-"}
            </div>
          {/* )} */}
        </>
      )}
      <div className={styles.heading}>Deskripsi Paket</div>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: detail.content }}
      />
    </div>
  );
}

import { isMobileCheck } from "../utils";
import ProfileDesktop from "../components/Profile/ProfileDesktop";
import ProfileMobile from "./Profile/ProfileMobile";

export default function ProfileUser() {
  return <ProfileMobile />
  // ) : (
  //   <ProfileDesktop isMobile={isMobileCheck()} />
  // );
}

import { Col, Container, Image, Row } from "react-bootstrap";
import HeroShotSearch from "../Search/HeroShotSearch";
import Description from "../Search/Description";
import IncludeExclude from "../Search/IncludeExclude";
import Itinerary from "../Search/Itinerary";
import FAQ from "../../pages/FAQ";
import DepartureLocation from "../Search/DepartureLocation";
import StickyInformation from "../Search/StickyInformation";
import clock from "./../../assets/clock.svg";
import location from "./../../assets/location.svg";
import profile from "./../../assets/profile.svg";
import wallet from "./../../assets/empty-wallet.svg";
import { useNavigate, useParams } from "react-router-dom";
import { formatRupiah } from "../../constant/lib";
import { isMobileCheck } from "../../utils";
import NavigationDetail from "../DetailCatalogue/NavigationDetail";

export default function TourDetail(props) {
  const {
    detail,
    isLogin,
    checkoutPackages,
    handleBack,
    addWishlist,
    styles,
    handleAdd,
    count,
    addToCart,
  } = props;
  const currUrl = useParams();
  const navigate = useNavigate();
  return (
    <>
      <HeroShotSearch
        detail={detail}
        isLogin={isLogin}
        checkoutPackages={checkoutPackages}
        handleBack={handleBack}
        addWishlist={addWishlist}
      />
      <Container>
        <Row className={styles.contentWrap}>
          {!isMobileCheck() && (
            <NavigationDetail type="tour" styles={styles} data={detail} />
          )}
          <Col lg={7} className={`${styles.detail} `}>
            <Description detail={detail} />
            <IncludeExclude detail={detail} type="tour" />
            <DepartureLocation detail={detail} />
            <Itinerary detail={detail} />
            <FAQ detail={detail} />
          </Col>
          <Col lg={4} className={`${styles.summary} offset-lg-1`}>
            <div className={styles.summaryWrap}>
              <div className={`${styles.iconBox} mb-2 mb-md-4`}>
                <div className={styles.titleIconBox}>
                  <Image src={clock} alt="" />
                  Durasi Keberangkatan
                </div>
                <div className={styles.detailIconBox}>
                  {detail.duration ? detail.duration : "-"}
                </div>
              </div>
              <div className={`${styles.iconBox} mb-2 mb-md-4`}>
                <div className={styles.titleIconBox}>
                  <Image src={location} alt="" />
                  Lokasi Keberangkatan
                </div>
                <div className={styles.detailIconBox}>
                  <>
                    {detail.location?.name
                      ? detail.location?.name
                      : detail?.location}
                  </>
                </div>
              </div>
              {isLogin ? (
                <>
                  <div className={`${styles.quantityWrap} mt-4 mb-5`}>
                    <div className={styles.quantityOrder}>
                      <div className={styles.titleQuantityOrder}>
                        <Image src={profile} alt="" />
                        Jumlah Guest
                      </div>
                      <div className={styles.detailQuantityOrder}>
                        <div
                          className={`${styles.btnInput} me-3`}
                          onClick={() => handleAdd("decrement")}
                        >
                          -
                        </div>
                        <input className={styles.qty} value={count} />
                        <div
                          className={`${styles.btnInput} ms-3`}
                          onClick={() => handleAdd("increment")}
                        >
                          +
                        </div>
                      </div>
                    </div>
                    <div className={styles.totalBox}>
                      <div>
                        <Image src={wallet} alt="" />
                        Subtotal
                      </div>
                      <div className={styles.total}>
                        {currUrl.package !== "flight" ? (
                          <>
                            {formatRupiah(
                              (detail.discount_percent !== null
                                ? detail.discount_percent
                                : detail.price) * count
                            )}
                          </>
                        ) : (
                          <>{formatRupiah(detail.min_price * count)}</>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div
                    onClick={(e) => addToCart(e, detail)}
                    className={`btnYellow mb-4 w-100 ${styles.addToCart}`}
                  >
                    + Keranjang
                  </div> */}
                  <div
                    onClick={checkoutPackages}
                    className={`btnYellow btnYellow__outline mb-3 w-100 ${styles.addToCart}`}
                  >
                    Beli Paket
                  </div>
                </>
              ) : (
                <div
                  onClick={() => navigate("/login")}
                  className={`btnYellow btnYellow__outline mt-4 mb-2 w-100 ${styles.addToCart}`}
                >
                  Login untuk Memesan
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

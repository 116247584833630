import { Container } from "react-bootstrap";
import WebLayout from "../components/Layout/WebLayout";
import HeroShot from "../components/Home/HeroShot";
import CustomPackages from "../components/Home/CustomPackages";
import PlaceList from "../components/Home/PlaceList";
import PopularPackages from "../components/Home/PopularPackages";
import Shortcut from "../components/Home/ShortCut";
import { useEffect, useState } from "react";
import { PlaceServices } from "../http/PlaceHttp";
import styles from "./../styles/Home.module.scss";
import { AuthServices } from "../http/AuthHttp";
import { useNavigate } from "react-router-dom";
import FilterSection from "../components/FilterSection";
import Skeleton from "react-loading-skeleton";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import hajjIcon from "../assets/icon-home/hajj-icon.png";
import hotelIcon from "../assets/icon-home/hotel-icon.png";
import ticketBusIcon from "../assets/icon-home/ticket-bus-icon.png";
import ticketPlaneIcon from "../assets/icon-home/ticket-pesawat-icon.png";
import tourIcon from "../assets/icon-home/tour-icon.png";
import trainIcon from "../assets/icon-home/train-icon.png";
import umrohIcon from "../assets/icon-home/umroh-icon.png";
import visaIcon from "../assets/icon-home/visa-icon.png";
import moment from "moment";
export default function Home() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPopular, setIsLoadingPopular] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [popularPackages, setPopularPackages] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [busList, setBusList] = useState([]);
  const [trainList, setTrainList] = useState([]);
  const [planeList, setPlaneList] = useState([]);
  const [type, setType] = useState("hotel");
  const [typeMobile, setTypeMobile] = useState(null);
  const [showNav, setShowNav] = useState(true);
  useEffect(() => {
    getPlaceList("hotel");
    getPlaceList("packages");
  }, []);

  useEffect(() => {
    let windowWidth = window.innerWidth;
    var isMobile = false;
    if (windowWidth <= 768) {
      isMobile = true;
    } else {
      isMobile = false;
    }
    setIsMobile(isMobile);
  }, []);

  useEffect(() => {
    let checkLogin = localStorage.getItem("riyuz-auth");
    let parse = JSON.parse(checkLogin);
    if (parse?.token) {
      getData();
    }
  }, []);

  const getData = async () => {
    setIsLoading(true);
    let data = localStorage.getItem("riyuz-auth");
    data = data && JSON.parse(data);
    try {
      const response = await AuthServices.getMe(`?id=${data.id}`);
      if (response) {
        setIsLoading(false);
      }
    } catch (err) {
      localStorage.removeItem("riyuz-auth");
      navigate("/login");
      setIsLoading(false);
    }
  };

  const getPlaceList = async (place) => {
    if (place === "packages") {
      try {
        const response = await PlaceServices.tourList(`?limit=5&is_featured=1`);
        if (response) {
          setIsLoadingPopular(false);
          setPopularPackages(response.data);
        }
      } catch (err) {
        setIsLoadingPopular(false);
      }
    } else {
      setIsLoading(true);
      try {
        let response = await PlaceServices.placeList(place, `?limit=4`);
        if (response) {
          setIsLoading(false);
          if (place === "hotel") {
            setHotelList(response.message);
          } else if (place === "bus") {
            setBusList(response.message);
          } else if (place === "flight") {
            setPlaneList(response.message);
          } else {
            setTrainList(response.message);
          }
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  };

  const activeTab = (type) => {
    setType(type);
    if (type === "hotel") {
      setHotelList([]);
      getPlaceList("hotel");
    } else if (type === "pesawat") {
      setPlaneList([]);
      getPlaceList("flight");
    } else if (type === "bus") {
      setBusList([]);
      getPlaceList("bus");
    } else if (type === "kereta") {
      setTrainList([]);
      getPlaceList("kereta");
    }
  };

  const showFilter = (type) => {
    if (
      isMobile &&
      (type.value == "hajj" ||
        type.value == "umrah" ||
        type.value == "hotel" ||
        type.value == "tour")
    ) {
      setShowNav(false);
      setTypeMobile(type);
    } else {
      let currentDate = moment(new Date()).format("YYYY-MM-DD");
      let nextDate = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
      navigate(
        `/search?type=${type.value}&cat_id[]=${type.value}&start=${currentDate}&end=${nextDate}&view=desktop`
      );
    }
  };

  const mainCategory = [
    {
      label: "Haji",
      value: "hajj",
      icon: hajjIcon,
    },
    {
      label: "Umroh",
      value: "umrah",
      icon: umrohIcon,
    },
  ];

  const moreCategory = [
    { label: "Tour", value: "tour", icon: tourIcon },
    {
      label: "Hotel",
      value: "hotel",
      icon: hotelIcon,
    },
    {
      label: "Tiket Pesawat",
      value: "flight",
      icon: ticketPlaneIcon,
    },
    {
      label: "Tiket Bus",
      value: "bus",
      icon: ticketBusIcon,
    },
    {
      label: "Tiket Kereta",
      value: "train",
      icon: trainIcon,
    },
    {
      label: "Visa",
      value: "visa",
      icon: visaIcon,
    },
  ];

  const resetField = () => {
    setTypeMobile(null);
    setShowNav(true);
  };

  useEffect(() => {
    console.log(typeMobile);
  }, [typeMobile]);

  return (
    <WebLayout showNav={showNav}>
      {isMobile && typeMobile ? (
        <></>
      ) : (
        <>
          <div className="d-md-block d-none">
            <HeroShot />
          </div>
          <Container className="d-block px-md-5 px-3">
            <div className={styles.mobileHero}>
              <div className={styles.mobileHero__top}>
                {mainCategory.map((_, index) => (
                  <div
                    key={index}
                    className={`${
                      index === 0
                        ? styles.mobileHero__top__left
                        : styles.mobileHero__top__right
                    }`}
                    onClick={() => showFilter(_)}
                  >
                    <div className={styles.mobileHero_title}>{_.label}</div>
                    <div className={styles.mobileHero_icon}>
                      <img src={_.icon} alt="icon" />
                    </div>
                  </div>
                ))}
              </div>

              <div className={styles.mobileHero__bottom}>
                <Splide
                  options={{
                    // type: "loop",
                    pagination: true,
                    gap: "1rem",
                    // fixedWidth: "calc(100% - 84px)",
                    perMove: 1,
                    perPage: 3,
                    arrows: false,
                    breakpoints: {
                      768: {
                        gap: "1.25rem",
                      },
                    },
                  }}
                >
                  {moreCategory.map((_, index) => (
                    <SplideSlide key={index}>
                      <div
                        className={styles.mobileHero__bottom_item}
                        onClick={() => showFilter(_)}
                      >
                        <div className={styles.mobileHero_title}>{_.label}</div>
                        <div className={styles.mobileHero__bottom_icon}>
                          <img src={_.icon} alt="icon" />
                        </div>
                      </div>
                    </SplideSlide>
                  ))}
                </Splide>
              </div>
            </div>
          </Container>
        </>
      )}

      <Container className={styles.content}>
        {/* <div className="d-none d-md-block">
          <FilterSection
            type="full"
            isMobile={isMobile}
            resetField={resetField}
          />
        </div> */}
        {/* <div className="d-none d-md-block">
          <FilterSection
            type={typeMobile}
            isMobile={isMobile}
            resetField={resetField}
          />
        </div> */}
        {isMobile && typeMobile ? (
          // <div className="d-md-none d-block">
          <FilterSection
            type={typeMobile}
            isMobile={isMobile}
            resetField={resetField}
          />
        ) : (
          // </div>
          <>
            <div className={styles.calculatorWrap}></div>
            <div className={`d-flex flex-column ${styles.popularWrap}`}>
              <PopularPackages
                data={popularPackages}
                isLoading={isLoadingPopular}
              />
            </div>
        {!isMobile && <CustomPackages />}

            <div className="mt-4 mt-md-5">
              <div className={styles.heading}>
                <h2 className={`${styles.titleHome}`}>
                  Rekomendasi Layanan
                </h2>
                <label>Rekomendasi layanan terbaik dari kami saat ini</label>
              </div>

              <div className={`${styles.tabCategory}`}>
                <div
                  className={`${styles.tab} ${
                    type === "hotel" ? styles.tab__active : ""
                  }`}
                  onClick={() => activeTab("hotel")}
                >
                  Hotel
                </div>
                <div
                  className={`${styles.tab} ${
                    type === "pesawat" ? styles.tab__active : ""
                  }`}
                  onClick={() => activeTab("pesawat")}
                >
                  Pesawat
                </div>
                <div
                  className={`${styles.tab} ${
                    type === "bus" ? styles.tab__active : ""
                  }`}
                  onClick={() => activeTab("bus")}
                >
                  Bus
                </div>
                <div
                  className={`${styles.tab} ${
                    type === "kereta" ? styles.tab__active : ""
                  }`}
                  onClick={() => activeTab("kereta")}
                >
                  Kereta
                </div>
              </div>
            </div>
            {type === "hotel" && (
              <PlaceList
                title="Hotel"
                data={hotelList}
                type="hotel"
                isLoading={isLoading}
              />
            )}
            {type === "pesawat" && (
              <PlaceList
                title="Pesawat"
                data={planeList}
                type="flight"
                isLoading={isLoading}
              />
            )}
            {type === "bus" && (
              <PlaceList
                title="Bus"
                data={busList}
                type="bus"
                isLoading={isLoading}
              />
            )}
            {type === "kereta" && (
              <PlaceList
                title="Kereta"
                data={trainList}
                type="train"
                isLoading={isLoading}
              />
            )}
          </>
        )}
      </Container>
    </WebLayout>
  );
}

import { Button, Col, Form, Image, Modal, Row, Table } from "react-bootstrap";
import styles from "./../../styles/Profile.module.scss";
import arrowLeft from "./../../assets/Profile/arrow-left.svg";
import { useEffect, useState } from "react";
import FormInput from "./ProfileMobile/FormInput";
import { BusesServices } from "../../http/BusesHttp";
import { FlightServices } from "../../http/FlightHttp";
import { RoomServices } from "../../http/RoomHttp";
import { JamaahServices } from "../../http/JamaahHttp";
import { AddonServices } from "../../http/AddonHttp";
import { formatRupiah } from "../../constant/lib";
import { CorporatePackagesServices } from "../../http/CorporatePackagesHttp";
import { UmrohClaimServices } from "../../http/UmrohClaimHttp";
import moment from "moment";
import { VisaServices } from "../../http/VisaHttp";

export default function InformationUmroh(props) {
  const { data, isMobile, setIsLoading, getData, typeMenu } = props;
  const [type, setType] = useState("grup");
  const [dataInput, setDataInput] = useState({ ...data });
  const [selectedBus, setSelectedBus] = useState();
  const [selectedFlight, setSelectedFlight] = useState();
  const [idAddOn, setIdAddOn] = useState();
  const [selectedAddOnEdit, setSelectedAddOnEdit] = useState();
  const [totalAddOnEdit, setTotalAddOnEdit] = useState(0);

  const [modalAddon, setModalAddon] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [subCategoryAddOn, setSubCategoryAddOn] = useState(null);
  const [addOnList, setAddOnList] = useState(null);
  const [corporatePackages, setCorporatePackages] = useState(null);
  const [categoryAddOn, setCategoryAddOn] = useState(null);
  const [selectedAddOn, setSelectedAddOn] = useState(null);
  const [memberClaimSelected, setMemberClaimSelected] = useState(null);
  const [selectedCorporate, setSelectedCorporate] = useState(null);
  const [totalAddOnPrice, setTotalAddOnPrice] = useState(0);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [dataAddon, setDataAddon] = useState();
  const [visaSelected, setVisaSelected] = useState();
  const [selectedRoom, setSelectedRoom] = useState();

  useEffect(() => {
    if (type === "akomodasi") {
      getBusSelected(null, data);
      getFlightSelected(null, data);
      getRoomSelected(null, data);
    }
    if (type === "grup") {
      getCorporatePackagesList(
        null,
        `?corporate_id=${dataInput?.corporate_id?.id}`
      );
    }
    if (type === "member-claim") {
      getMemberClaimSelectedPilgrims(dataInput?.member_claim_id?.id);
    }
    if (type === "visa") {
      getVisaSelectedPilgrims(dataInput?.visa_id, null);
    }
  }, [type, data]);

  useEffect(() => {
    if (modalAddon === false) {
      setTotalAddOnPrice(0);
      setSelectedAddOn(null);
      setAddOnList(null);
      setCategoryAddOn(null);
      setSubCategoryAddOn(null);
    }
  }, [modalAddon]);

  useEffect(() => {
    if (addOnList?.length > 0) {
      if (dataInput?.addon_id) {
        let selected = addOnList.find((item) => item.id == dataInput.addon_id);
        setSelectedAddOn(selected);
      }

      let idSelected = dataAddon?.addon_id?.id;
      let findData = addOnList.find((item) => item.id == idSelected);
      setSelectedAddOnEdit(findData);
    }
  }, [addOnList, dataInput]);

  useEffect(() => {
    if (dataAddon) {
      let totalEdit = dataAddon?.amount * dataAddon?.price;
      if (selectedAddOnEdit) {
        totalEdit = selectedAddOnEdit?.price * dataAddon?.amount;
      }
      setTotalAddOnEdit(totalEdit);
    }
  }, [dataAddon, selectedAddOnEdit]);

  useEffect(() => {
    if (corporatePackages?.length > 0) {
      let selected = corporatePackages.find(
        (item) => item.id == dataInput?.program_pilihan
      );
      setSelectedCorporate(selected);
    }
  }, [corporatePackages, dataInput]);

  useEffect(() => {
    if (dataInput?.amount) {
      let intAmount = dataInput?.amount ? parseInt(dataInput?.amount) : 0;
      let intPrice = selectedAddOn?.price ? selectedAddOn.price : 0;
      let total = intPrice * intAmount;
      setTotalAddOnPrice(total);
    }
  }, [selectedAddOn, dataInput]);

  useEffect(() => {
    if (dataInput?.category_addon) {
      getAddOnSubOrCat(
        null,
        `?parentId=1&parent_id=${dataInput?.category_addon}`
      );
    }
  }, [dataInput]);

  useEffect(() => {
    if (typeMenu === "trip") {
      getCorporatePackagesList(
        null,
        `?corporate_id=${dataInput?.corporate_id?.id}&size=100`
      );
    }
  }, [typeMenu]);

  useEffect(() => {
    if (corporatePackages) {
      let selectedProgramData = corporatePackages.find(
        (item) => item.id == dataInput?.program_pilihan
      );
      setSelectedProgram(selectedProgramData);
    }
  }, [corporatePackages, dataInput]);

  const handleDeleteAddon = async (e) => {
    e.preventDefault();
    setModalDelete(false);
    try {
      const response = await AddonServices.deleteAddOnSelected(idAddOn);
      if (response) {
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpen = (type) => {
    setType(type);
  };

  const getBusSelected = async (id, data) => {
    setIsLoading(true);
    try {
      const response = await BusesServices.getBuses(
        id,
        `?corporate_id=${dataInput?.corporate_id?.id}&umroh_batch_id=${dataInput?.umroh_batch_id?.id}`
      );
      if (response) {
        setIsLoading(false);
        let busList = response?.data?.data;
        let busSelected = busList.find((bus) => bus.id === dataInput?.bus);
        setSelectedBus(busSelected);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getFlightSelected = async (id, data) => {
    setIsLoading(true);
    try {
      const response = await FlightServices.getListFlightUmroh(
        id,
        `?umroh_batch_id=${dataInput?.umroh_batch_id?.id}`
      );
      if (response) {
        setIsLoading(false);
        let flightList = response?.data?.data;
        let flightSelected = flightList.find(
          (flight) => flight.id === dataInput?.flight_id
        );
        setSelectedFlight(flightSelected);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getCorporatePackagesList = async (id, param) => {
    setIsLoading(true);
    try {
      const response = await CorporatePackagesServices.getCorporatePackages(
        id,
        param
      );
      if (response) {
        setIsLoading(false);
        setCorporatePackages(response.data.data);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getMemberClaimSelectedPilgrims = async (id, param) => {
    setIsLoading(true);
    try {
      let response = await UmrohClaimServices.getUmrohClaim(id, param);
      if (response) {
        setMemberClaimSelected(response.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const getVisaSelectedPilgrims = async (id, param) => {
    setIsLoading(true);
    try {
      let response = await VisaServices.getVisas(id, param);
      if (response) {
        setIsLoading(false);
        setVisaSelected(response.data);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const getRoomSelected = async (id, data) => {
    setIsLoading(true);
    try {
      const response = await RoomServices.getRooms(
        id,
        `?pic_id=${dataInput?.pic_id?.id}&limit=100&offset=0`
      );
      if (response) {
        setIsLoading(false);
        let roomList = response?.data?.data;
        let roomSelected = roomList.find(
          (room) => room.id === dataInput?.room_id
        );
        setSelectedRoom(roomSelected);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const getAddOnSubOrCat = async (id, param) => {
    setIsLoading(true);
    try {
      const response = await AddonServices.getAddOn(id, param);
      if (response) {
        setIsLoading(false);
        let splitParam = param.split("=");
        if (splitParam[1] == 0) {
          setCategoryAddOn(response.data.data);
        } else {
          setSubCategoryAddOn(response.data.data);
        }
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const getAddOnList = async (id, param) => {
    setIsLoading(true);
    try {
      const response = await AddonServices.getAddOnsList(id, param);
      if (response) {
        setIsLoading(false);
        // console.log(response.data.data);
        setAddOnList(response.data.data);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const newData = { ...dataInput };
    if (e.target.id === "dokumen_passport") {
      newData[e.target.id] = e.target.files[0];
    } else {
      newData[e.target.id] = e.target.value;
    }
    setDataInput(newData);
  };

  const handleChangeAddOn = (e) => {
    const newData = { ...dataAddon };
    newData[e.target.id] = e.target.value;
    setDataAddon(newData);
  };

  const menuList = [
    { text: "Data Grup", type: "grup" },
    { text: "Akomodasi", type: "akomodasi" },
    { text: "Visa", type: "visa" },
    { text: "Member Claim", type: "member-claim" },
    { text: "Passport", type: "passport" },
    { text: "Addon", type: "addon" },
    { text: "Perlengkapan", type: "equipment" },
    { text: "Ciri Fisik", type: "ciri" },
    { text: "Lainnya", type: "other" },
  ];

  const menuTab = (typeActive, text) => {
    return (
      <div
        className={`menuSub ${type === typeActive ? "menuSub_active" : ""}`}
        onClick={() => handleOpen(typeActive)}
      >
        {text}
      </div>
    );
  };

  const handleAddon = () => {
    setModalAddon(!modalAddon);
  };

  const handleAddAddon = async (e) => {
    setIsLoading(true);
    console.log(dataInput);
    e.preventDefault();
    // const newDataInput = {
    //   addon_id: dataInput?.addon_id,
    //   amount: dataInput.amount,
    //   duration: "1",
    //   price: selectedAddOn?.price,
    //   price_total: totalAddOnPrice,
    //   user_id: dataInput?.user_id?.id,
    //   nama_lengkap: dataInput?.nama_lengkap,
    // };
    // try {
    //   const payload = {
    //     id: dataInput?.id,
    //     body: newDataInput,
    //   };
    //   const response = await JamaahServices.putJamaah(payload);
    //   if (response) {
    //     setIsLoading(false);
    //     getData();
    //     setCategoryAddOn(null);
    //     setSubCategoryAddOn(null);
    //     setModalAddon(false);
    //   }
    // } catch (err) {
    //   setIsLoading(false);
    //   console.log(err);
    // }
  };

  const handleEditMemberClaim = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    // let newData = { ...textResponse };
    let payload = {
      user_id: dataInput?.user_id?.id,
      nama_lengkap: dataInput?.nama_lengkap,
      bank_account_name: memberClaimSelected?.bank_account_name,
      bank_account_number: memberClaimSelected?.bank_account_number,
      bank_name: memberClaimSelected?.bank_name,
      ket_promo: memberClaimSelected?.ket_promo,
      phone: memberClaimSelected?.phone,
      redeem_code: memberClaimSelected?.redeem_code,
      redeem_date:
        memberClaimSelected?.redeem_date !== null ||
        memberClaimSelected?.redeem_date !== "null"
          ? moment(memberClaimSelected?.redeem_date).format("YYYY-MM-DD")
          : "",
      usernames: memberClaimSelected?.usernames,
    };
    let payloadEdit = {
      id: dataInput?.member_claim_id?.id,
      body: payload,
    };
    // console.log(dataInput);
    try {
      let response = await UmrohClaimServices.putUmrohClaim(payloadEdit);
      if (response) {
        setIsLoading(false);
        getData();
        setMemberClaimSelected(response?.data);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const generatePayload = (type) => {
    switch (type) {
      case "passport":
        const formData = new FormData();
        // let payloadPassport = {};
        formData.append("user_id", dataInput?.user_id?.id);
        formData.append("nama_lengkap", dataInput?.nama_lengkap);
        formData.append(
          "nama_sesuai_passport",
          dataInput?.nama_sesuai_passport != null ||
            dataInput?.nama_sesuai_passport != "null"
            ? dataInput?.nama_sesuai_passport
            : ""
        );
        formData.append(
          "no_passport",
          dataInput?.no_passport != null || dataInput?.no_passport != "null"
            ? dataInput?.no_passport
            : ""
        );
        formData.append(
          "passport_berlaku",
          dataInput?.passport_berlaku != "Invalid date" ||
            dataInput?.passport_berlaku != "null" ||
            dataInput?.passport_berlaku != null
            ? moment(dataInput?.passport_berlaku).format("YYYY-MM-DD")
            : ""
        );
        formData.append(
          "no_passport",
          dataInput?.no_passport != null || dataInput?.no_passport != "null"
            ? dataInput?.no_passport
            : ""
        );

        formData.append(
          "dokumen_passport",
          dataInput?.dokumen_passport != null ||
            dataInput?.dokumen_passport != "null"
            ? dataInput?.dokumen_passport
            : ""
        );

        formData.append("is_fisik_passport", dataInput?.is_fisik_passport);

        return formData;
      case "ciri":
        let payloadCiri = {};
        payloadCiri.user_id = dataInput?.user_id?.id;
        payloadCiri.nama_lengkap = dataInput?.nama_lengkap;
        if (dataInput?.hidung) payloadCiri.hidung = dataInput.hidung;
        if (dataInput?.alis) payloadCiri.alis = dataInput.alis;
        if (dataInput?.tinggi) payloadCiri.tinggi = dataInput.tinggi;
        if (dataInput?.rambut) payloadCiri.rambut = dataInput.rambut;
        if (dataInput?.muka) payloadCiri.muka = dataInput.muka;
        if (dataInput?.berat) payloadCiri.berat = dataInput.berat;
        return payloadCiri;
      case "equipment":
        let payloadEquipment = {};
        payloadEquipment.user_id = dataInput?.user_id?.id;
        payloadEquipment.nama_lengkap = dataInput?.nama_lengkap;
        if (dataInput?.briefcase)
          payloadEquipment.briefcase = parseInt(dataInput.briefcase);
        if (dataInput?.equipment)
          payloadEquipment.equipment = parseInt(dataInput.equipment);
        if (dataInput?.shipment) payloadEquipment.shipment = dataInput.shipment;
        if (dataInput?.shipment_remarks)
          payloadEquipment.shipment_remarks = dataInput.shipment_remarks;
        return payloadEquipment;
      case "addon":
        let payloadAddon = {};
        payloadAddon.user_id = dataInput?.user_id?.id;
        payloadAddon.nama_lengkap = dataInput?.nama_lengkap;
        if (dataInput?.addon_id)
          payloadAddon.addon_id = parseInt(dataInput?.addon_id);
        if (dataInput?.amount)
          payloadAddon.amount = parseInt(dataInput?.amount);
        payloadAddon.duration = 1;
        if (dataInput?.price) payloadAddon.price = parseInt(dataInput?.price);
        if (dataInput?.price_total)
          payloadAddon.price_total = parseInt(dataInput?.price_total);

        return payloadAddon;
      case "other":
        let payloadOther = {};
        payloadOther.user_id = dataInput?.user_id?.id;
        payloadOther.nama_lengkap = dataInput?.nama_lengkap;
        if (dataInput?.additional_discount) {
          payloadOther.additional_discount = dataInput?.additional_discount;
        }
        if (dataInput?.additional_discount_remark) {
          payloadOther.additional_discount_remark =
            dataInput?.additional_discount_remark;
        }
        if (dataInput?.additional_equipment_price) {
          payloadOther.additional_equipment_price =
            dataInput?.additional_equipment_price;
        }
        if (dataInput?.additional_equipment_remark) {
          payloadOther.additional_equipment_remark =
            dataInput?.additional_equipment_remark;
        }
        if (dataInput?.additional_addon_price) {
          payloadOther.additional_addon_price =
            dataInput?.additional_addon_price;
        }
        if (dataInput?.additional_addon_remark) {
          payloadOther.additional_addon_remark =
            dataInput?.additional_addon_remark;
        }
        if (dataInput?.food_request) {
          payloadOther.food_request = dataInput?.food_request;
        }
        if (dataInput?.ket_promo) {
          payloadOther.ket_promo = dataInput?.ket_promo;
        }
        if (dataInput?.add_notes) {
          payloadOther.add_notes = dataInput?.add_notes;
        }

        return payloadOther;
      default:
        break;
    }
  };

  const handleSubmitData = async (e) => {
    e.preventDefault();
    // console.log(generatePayload(type));
    // console.log(generatePayload(type));
    setIsLoading(true);
    try {
      const payload = {
        id: dataInput?.id,
        body: generatePayload(type),
      };
      const response = await JamaahServices.putJamaah(payload);
      if (response) {
        setIsLoading(false);
        getData();

        // navigate("/profile");
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleSaveVisa = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let payload = {
      pilgrim_id: dataInput?.id,
      place_of_issue: visaSelected?.place_of_issue,
      date_of_issue: moment(visaSelected?.date_of_issue).format("YYYY-MM-DD"),
      date_of_expiration: moment(visaSelected?.date_of_expiration).format(
        "YYYY-MM-DD"
      ),
    };
    let payloadEdit = {
      id: dataInput?.visa_id,
      body: payload,
    };
    try {
      let response = await VisaServices.putVisa(payloadEdit);
      if (response) {
        setIsLoading(false);
        setVisaSelected(response?.data);
        getData();
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleChangeMemberClaim = (e) => {
    const newData = { ...memberClaimSelected };
    newData[e.target.id] = e.target.value;
    setMemberClaimSelected(newData);
  };

  const handleChangeVisa = (e) => {
    const newData = { ...visaSelected };
    newData[e.target.id] = e.target.value;
    setVisaSelected(newData);
  };

  const showModalDelete = (id) => {
    setModalDelete(true);
    setIdAddOn(id);
  };

  const handleEditAddon = (item) => {
    setModalEdit(!modalEdit);
    setDataAddon(item);
    setIdAddOn(item.id);
    getAddOnList(
      null,
      `?category_id=${item?.addon_id?.category_id}${
        item?.addon_id?.subcategory_id
          ? `&subcategory_id=${item?.addon_id?.subcategory_id}`
          : ""
      }`
    );
  };

  const handleSaveEditAddon = async (e) => {
    e.preventDefault();
    let editAddon = {
      pilgrim_id: parseInt(dataInput?.id),
      price: selectedAddOnEdit
        ? parseInt(selectedAddOnEdit?.price)
        : parseInt(dataAddon?.price),
      price_total: parseInt(totalAddOnEdit),
      duration: "1",
      amount: dataAddon?.amount,
    };
    try {
      // editDataAddon.total_addon_price =
      let payload = {
        id: parseInt(dataAddon?.id),
        body: editAddon,
      };

      const response = await JamaahServices.putAddOnPilgrimsSelected(payload);
      if (response) {
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Col lg={8}>
      <div className={styles.menuShow}>
        <h1 className="mb-4 mb-md-4">
          My{" "}
          {typeMenu === "my-packages"
            ? "Packages"
            : typeMenu === "equipment"
            ? "Equipment"
            : typeMenu === "trip"
            ? "Trip"
            : "Retail"}
        </h1>
        {typeMenu === "my-packages" && (
          <div className="pb-4">
            <div
              className="d-flex pb-2"
              style={{
                gap: "10px",
                textWrap: "nowrap",
                width: "100%",
                overflow: "auto",
              }}
            >
              {/* <div>Data Umroh</div> */}
              {menuList.map((item, idx) => {
                return menuTab(item.type, item.text);
              })}
            </div>
          </div>
        )}
        {typeMenu === "my-packages" ? (
          <Form>
            {type === "grup" && (
              <Row>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Perusahaan"
                    id="corporate_id"
                    handleChange={handleChange}
                    type="text"
                    value={dataInput?.corporate_id?.name}
                    disable={true}
                  />
                </Col>{" "}
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Kloter"
                    id="umroh_batch_id"
                    handleChange={handleChange}
                    type="text"
                    value={dataInput?.umroh_batch_id?.name}
                    disable={true}
                  />
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Leader"
                    id="leader_id"
                    handleChange={handleChange}
                    type="text"
                    value={dataInput?.leader_id?.name}
                    disable={true}
                  />
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="PIC"
                    id="pic_id"
                    handleChange={handleChange}
                    type="text"
                    value={dataInput?.pic_id?.name}
                    disable={true}
                  />
                </Col>
                <Col lg={12} className="d-flex flex-column mb-4">
                  <FormInput
                    label="Program Pilihan Jamaah"
                    id="program_pilihan"
                    handleChange={handleChange}
                    type="text"
                    value={selectedCorporate?.name}
                    disable={true}
                  />
                </Col>
              </Row>
            )}
            {type === "ciri" && (
              <Row>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Hidung"
                    id="hidung"
                    handleChange={handleChange}
                    value={
                      dataInput?.hidung == "null" || dataInput?.hidung == null
                        ? undefined
                        : dataInput?.hidung
                    }
                    type="text"
                  />
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Alis"
                    id="alis"
                    value={
                      dataInput?.alis == "null" || dataInput?.alis == null
                        ? undefined
                        : dataInput?.alis
                    }
                    handleChange={handleChange}
                    type="text"
                  />
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Tinggi"
                    id="tinggi"
                    value={
                      dataInput?.tinggi == "null" || dataInput?.tinggi == null
                        ? undefined
                        : dataInput?.tinggi
                    }
                    handleChange={handleChange}
                    type="text"
                  />
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Rambut"
                    id="rambut"
                    value={
                      dataInput?.rambut == "null" || dataInput?.rambut == null
                        ? undefined
                        : dataInput?.rambut
                    }
                    handleChange={handleChange}
                    type="text"
                  />
                </Col>{" "}
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Muka"
                    value={
                      dataInput?.muka == "null" || dataInput?.muka == null
                        ? undefined
                        : dataInput?.muka
                    }
                    id="muka"
                    handleChange={handleChange}
                    type="text"
                  />
                </Col>{" "}
                <Col lg={6} className="d-flex flex-column mb-4">
                  <FormInput
                    label="Berat"
                    value={
                      dataInput?.berat == "null" || dataInput?.berat == null
                        ? undefined
                        : dataInput?.berat
                    }
                    id="berat"
                    handleChange={handleChange}
                    type="text"
                  />
                </Col>
              </Row>
            )}
            {type === "akomodasi" && (
              <Row>
                <Col lg={12} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Bus"
                    id="bus"
                    handleChange={handleChange}
                    value={selectedBus?.name}
                    disable={true}
                    type="text"
                  />
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Penerbangan"
                    id="flight_id"
                    handleChange={handleChange}
                    value={selectedFlight?.flight_number}
                    disable={true}
                    type="text"
                  />
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="No. Bagasi"
                    value={dataInput?.baggage_number}
                    disable={true}
                    id="baggage_number"
                    handleChange={handleChange}
                    type="text"
                  />
                </Col>
                <Col lg={4} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Room Jamaah"
                    disable={true}
                    id="room_mate"
                    handleChange={handleChange}
                    value={selectedRoom?.room_mate}
                    type="text"
                  />
                </Col>
                <Col lg={4} className="d-flex flex-column mb-3">
                  <FormInput
                    disable={true}
                    label="Tipe Room"
                    id="room_type"
                    handleChange={handleChange}
                    value={
                      dataInput?.room_type === "undefined" ||
                      dataInput?.room_type === undefined
                        ? ""
                        : dataInput?.room_type
                    }
                    type="text"
                  />
                </Col>
                <Col lg={4} className="mb-4">
                  <FormInput
                    disable={true}
                    label="Hubungan Teman Sekamar"
                    id="roommate_relation"
                    handleChange={handleChange}
                    value={
                      dataInput?.roommate_relation == "null" ||
                      dataInput?.roommate_relation == null
                        ? undefined
                        : dataInput?.roommate_relation
                    }
                    type="text"
                  />
                </Col>
              </Row>
            )}
            {type === "visa" && (
              <Row>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Date of Expiration"
                    id="date_of_expiration"
                    handleChange={handleChangeVisa}
                    value={moment(visaSelected?.date_of_expiration).format(
                      "YYYY-MM-DD"
                    )}
                    type="date"
                  />
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Date of Issue"
                    id="date_of_issue"
                    value={moment(visaSelected?.date_of_issue).format(
                      "YYYY-MM-DD"
                    )}
                    handleChange={handleChangeVisa}
                    type="date"
                  />
                </Col>
                <Col lg={12} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Place of Issue"
                    id="place_of_issue"
                    value={visaSelected?.place_of_issue}
                    handleChange={handleChangeVisa}
                    type="text"
                  />
                </Col>
              </Row>
            )}
            {type === "member-claim" && (
              <Row>
                <Col lg={4} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Nama Akun Bank"
                    id="bank_account_name"
                    handleChange={handleChangeMemberClaim}
                    type="text"
                    value={memberClaimSelected?.bank_account_name}
                  />
                </Col>
                <Col lg={4} className="d-flex flex-column mb-3">
                  <FormInput
                    label="No. Rekening"
                    id="bank_account_number"
                    handleChange={handleChangeMemberClaim}
                    type="text"
                    value={memberClaimSelected?.bank_account_number}
                  />
                </Col>
                <Col lg={4} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Bank"
                    id="bank_name"
                    handleChange={handleChangeMemberClaim}
                    type="text"
                    value={memberClaimSelected?.bank_name}
                  />
                </Col>
                <Col lg={12} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Keterangan Promo"
                    id="ket_promo"
                    handleChange={handleChangeMemberClaim}
                    isTextArea={true}
                    value={memberClaimSelected?.ket_promo}
                  />
                </Col>
                <Col lg={4} className="d-flex flex-column mb-3">
                  <FormInput
                    label="No. Telepon"
                    id="phone"
                    handleChange={handleChangeMemberClaim}
                    type="text"
                    value={memberClaimSelected?.phone}
                  />
                </Col>
                <Col lg={4} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Kode Reedem "
                    id="redeem_code"
                    handleChange={handleChangeMemberClaim}
                    type="text"
                    value={memberClaimSelected?.redeem_code}
                  />
                </Col>
                <Col lg={4} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Tanggal Reedem"
                    id="redeem_date"
                    handleChange={handleChangeMemberClaim}
                    type="date"
                    value={memberClaimSelected?.redeem_date}
                  />
                </Col>{" "}
                <Col lg={12} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Username"
                    id="usernames"
                    handleChange={handleChangeMemberClaim}
                    isTextArea={true}
                    value={memberClaimSelected?.usernames}
                  />
                </Col>
              </Row>
            )}
            {type === "passport" && (
              <Row>
                <Col lg={6} className="d-flex flex-column">
                  <div className="mb-3">
                    <FormInput
                      label="Nama Sesuai Passport"
                      id="nama_sesuai_passport"
                      handleChange={handleChange}
                      type="text"
                      value={
                        dataInput?.nama_sesuai_passport == "null" ||
                        dataInput?.nama_sesuai_passport == null
                          ? undefined
                          : dataInput?.nama_sesuai_passport
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <FormInput
                      label="No. Passport"
                      id="no_passport"
                      handleChange={handleChange}
                      type="text"
                    />
                  </div>
                  <div className="mb-3">
                    <FormInput
                      label="Passport Berlaku"
                      id="passport_berlaku"
                      handleChange={handleChange}
                      type="date"
                      value={moment(dataInput?.passport_berlaku).format(
                        "YYYY-MM-DD"
                      )}
                    />
                  </div>
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <div className="mb-3">
                    <label>Status Kirim Passport</label>
                    <select
                      id="is_fisik_passport"
                      onChange={handleChange}
                      className="form-select"
                      value={dataInput?.is_fisik_passport}
                    >
                      <option hidden>-- Pilih Status Kirim Passport --</option>
                      <option value="0">Belum Dikirim</option>
                      <option value="1">Sudah Dikirim</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <FormInput
                      label="Foto Passport"
                      id="dokumen_passport"
                      handleChange={handleChange}
                      type="file"
                      // value={dataInput?.dokumen_passport}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {type === "equipment" && (
              <Row>
                <Col lg={6} className="d-flex flex-column">
                  <div className="mb-3">
                    <label>Koper Jamaah</label>
                    <select
                      id="briefcase"
                      onChange={handleChange}
                      className="form-select"
                      value={dataInput?.briefcase}
                    >
                      <option value={0}>Iya</option>
                      <option value={1}>Tidak</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label>Perlengkapan Umroh</label>
                    <select
                      id="equipment"
                      onChange={handleChange}
                      className="form-select"
                      value={dataInput?.equipment}
                    >
                      <option value={0}>Iya</option>
                      <option value={1}>Tidak</option>
                    </select>
                  </div>{" "}
                  <div className="mb-3">
                    <label>Shipment</label>
                    <select
                      id="shipment"
                      onChange={handleChange}
                      className="form-select"
                      value={dataInput?.shipment}
                    >
                      <option value={0}>Dipickup</option>
                      <option value={1}>Dikirim</option>
                    </select>
                  </div>
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <div className="mb-3">
                    <FormInput
                      label="Masukkan Alamat Shipment"
                      id="shipment_remarks"
                      handleChange={handleChange}
                      type="text"
                      value={dataInput?.shipment_remarks}
                      isTextArea={true}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {type === "addon" && (
              <Row>
                <Col lg={12} className="text-end">
                  <div className="btnWrapProfile">
                    <Button onClick={handleAddon}>Tambah Addon</Button>
                  </div>
                </Col>
                <Col lg={12} className="d-flex flex-column mb-3">
                  {dataInput?.umroh_pilgrim_addons?.length > 0 ? (
                    <Table>
                      <thead>
                        <tr>
                          <th>Addon</th>
                          <th>Jumlah</th>
                          <th>Harga Satuan</th>
                          <th>Total Harga</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataInput?.umroh_pilgrim_addons?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                {item.addon_id?.name}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                {item.amount}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                {formatRupiah(item.price)}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                {formatRupiah(
                                  item.addon_id?.price *
                                    parseInt(item.amount ? item.amount : 0)
                                )}
                              </div>
                            </td>
                            <td>
                              <Button
                                className="me-2"
                                variant="success"
                                onClick={() => handleEditAddon(item)}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => showModalDelete(item.id)}
                              >
                                Hapus
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="text-center py-5">Tidak ada Addon</div>
                  )}
                </Col>
              </Row>
            )}
            {type === "other" && (
              <Row>
                <Col lg={12}>
                  <div className="mb-3 fw-bold">Diskon Tambahan</div>
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Tambahan Diskon"
                    id="additional_discount"
                    handleChange={handleChange}
                    type="text"
                    value={dataInput?.additional_discount}
                  />
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Catatan Tambahan Diskon"
                    id="additional_discount_remark"
                    handleChange={handleChange}
                    type="text"
                    value={
                      dataInput?.additional_discount_remark == "null" ||
                      dataInput?.additional_discount_remark == null
                        ? undefined
                        : dataInput?.additional_discount_remark
                    }
                  />
                </Col>
                <Col lg={12}>
                  <div className="mb-3 fw-bold">Peralatan Tambahan</div>
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Tambahan Harga Peralatan"
                    id="additional_equipment_price"
                    handleChange={handleChange}
                    type="text"
                    value={dataInput?.additional_equipment_price}
                  />
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Catatan Tambahan Harga Peralatan"
                    id="additional_equipment_remark"
                    handleChange={handleChange}
                    type="text"
                    value={
                      dataInput?.additional_equipment_remark == "null" ||
                      dataInput?.additional_equipment_remark == null
                        ? undefined
                        : dataInput?.additional_equipment_remark
                    }
                  />
                </Col>
                <Col lg={12}>
                  <div className="mb-3 fw-bold">Harga Addon Tambahan</div>
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Tambahan Harga Addon"
                    id="additional_addon_price"
                    handleChange={handleChange}
                    type="text"
                    value={dataInput?.additional_addon_price}
                  />
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Catatan Tambahan Harga Addon"
                    id="additional_addon_remark"
                    handleChange={handleChange}
                    type="text"
                    value={
                      dataInput?.additional_addon_remark == "null" ||
                      dataInput?.additional_addon_remark == null
                        ? undefined
                        : dataInput?.additional_addon_remark
                    }
                  />
                </Col>
                <Col lg={12}>
                  <div className="mb-3 fw-bold">Lainnya</div>
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Request Makanan"
                    id="food_request"
                    handleChange={handleChange}
                    type="text"
                    value={
                      dataInput?.food_request == "null" ||
                      dataInput?.food_request == null
                        ? undefined
                        : dataInput?.food_request
                    }
                  />
                </Col>
                <Col lg={6} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Keterangan Promo"
                    id="ket_promo"
                    handleChange={handleChange}
                    type="text"
                    value={
                      dataInput?.ket_promo == "null" ||
                      dataInput?.ket_promo == null
                        ? undefined
                        : dataInput?.ket_promo
                    }
                  />
                </Col>
                <Col lg={12} className="d-flex flex-column mb-3">
                  <FormInput
                    label="Tambahkan Catatan"
                    id="add_notes"
                    handleChange={handleChange}
                    type="text"
                    value={
                      dataInput?.add_notes == "null" ||
                      dataInput?.add_notes == null
                        ? undefined
                        : dataInput?.add_notes
                    }
                    isTextArea={true}
                  />
                </Col>
              </Row>
            )}
            {/* CTA */}
            {type !== "addon" && (
              <Col lg={12} className={`d-flex flex-column btnWrapProfile`}>
                <div className="text-end">
                  <Button
                    onClick={
                      type === "member-claim"
                        ? handleEditMemberClaim
                        : type === "visa"
                        ? handleSaveVisa
                        : handleSubmitData
                    }
                    type="submit"
                    disabled={
                      type === "grup" ||
                      type === "akomodasi" ||
                      (type === "visa" && dataInput?.visa_id === null) ||
                      (type === "member-claim" &&
                        dataInput?.member_claim_id === null)
                        ? true
                        : false
                    }
                  >
                    Simpan
                  </Button>
                </div>
              </Col>
            )}
          </Form>
        ) : typeMenu === "equipment" ? (
          <Form>
            <Row>
              <Col lg={12} className="d-flex flex-column mb-3">
                {dataInput?.umroh_pilgrim_addons?.length > 0 ? (
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>Equipment</th>
                          <th>Jumlah</th>
                          <th>Harga Satuan</th>
                          <th>Total Harga</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataInput?.umroh_pilgrim_addons?.map(
                          (item, index) =>
                            item.addon_id?.category_id == 1 && (
                              <tr key={index}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {item.addon_id?.name}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {item.amount}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {formatRupiah(item.price)}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {formatRupiah(
                                      item.addon_id?.price *
                                        parseInt(item.amount ? item.amount : 0)
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="text-center py-5">Tidak ada Addon</div>
                )}
              </Col>
            </Row>
          </Form>
        ) : typeMenu === "retail" ? (
          <Form>
            <Row>
              <Col lg={12} className="d-flex flex-column mb-3">
                {dataInput?.umroh_pilgrim_addons?.length > 0 ? (
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>Equipment</th>
                          <th>Jumlah</th>
                          <th>Harga Satuan</th>
                          <th>Total Harga</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataInput?.umroh_pilgrim_addons?.map(
                          (item, index) =>
                            item.addon_id?.category_id != 1 && (
                              <tr key={index}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {item.addon_id?.name}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {item.amount}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {formatRupiah(item.price)}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {formatRupiah(
                                      item.addon_id?.price *
                                        parseInt(item.amount ? item.amount : 0)
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="text-center py-5">Tidak ada Addon</div>
                )}
              </Col>
            </Row>
          </Form>
        ) : typeMenu === "trip" ? (
          <Form>
            <Row>
              <Col lg={12} className="d-flex flex-column mb-3">
                {dataInput?.umroh_pilgrim_addons?.length > 0 ? (
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>Hari</th>
                          <th>Waktu Mulai</th>
                          <th>Waktu Selesai</th>
                          <th>Kegiatan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProgram?.itinerary?.length > 0 ? (
                          selectedProgram?.itinerary?.map((item, index) => {
                            return item.hari?.map((elm, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      Hari {index + 1}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      {elm.start_time}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      {elm.end_time}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      {elm.content}
                                    </div>
                                  </td>
                                </tr>
                              );
                            });
                          })
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center">
                              Tidak Ada Data
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="text-center py-5">Tidak ada Addon</div>
                )}
              </Col>
            </Row>
          </Form>
        ) : (
          <Form>
            <Row>
              <Col lg={12} className="d-flex flex-column mb-3">
                {dataInput?.umroh_pilgrim_addons?.length > 0 ? (
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>Equipment</th>
                          <th>Jumlah</th>
                          <th>Harga Satuan</th>
                          <th>Total Harga</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataInput?.umroh_pilgrim_addons?.map(
                          (item, index) =>
                            item.addon_id?.category_id == 7 && (
                              <tr key={index}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {item.addon_id?.name}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {item.amount}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {formatRupiah(item.price)}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {formatRupiah(
                                      item.addon_id?.price *
                                        parseInt(item.amount ? item.amount : 0)
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="text-center py-5">Tidak ada Addon</div>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </div>
      <Modal show={modalAddon} onHide={() => setModalAddon(false)} center>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Addon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddAddon}>
            <Row>
              <Col lg={12} className="d-flex flex-column mb-3">
                <label className="mb-2">Kategori Addon</label>
                <select
                  id="category_addon"
                  onClick={() => getAddOnSubOrCat(null, `?parentId=0`)}
                  onChange={handleChange}
                  className="form-select"
                >
                  <option hidden>-- Pilih Kategori Addon --</option>
                  {categoryAddOn ? (
                    categoryAddOn?.length > 0 ? (
                      categoryAddOn?.map((item, index) => {
                        return <option value={item.id}>{item.name}</option>;
                      })
                    ) : (
                      <option disabled>Tidak Ada Data</option>
                    )
                  ) : (
                    <option disabled>Menunggu Data</option>
                  )}
                </select>
              </Col>
              <Col lg={12} className="d-flex flex-column mb-3">
                <label className="mb-2">Sub Kategori Addon</label>
                <select
                  id="subcategory_addon"
                  disabled={dataInput?.category_addon ? false : true}
                  onChange={handleChange}
                  className="form-select"
                >
                  <option hidden>-- Pilih Sub Kategori Addon --</option>
                  {subCategoryAddOn ? (
                    subCategoryAddOn?.length > 0 ? (
                      subCategoryAddOn.map((item, index) => {
                        return <option value={item.id}>{item.name}</option>;
                      })
                    ) : (
                      <option disabled>Tidak Ada Data</option>
                    )
                  ) : (
                    <option disabled>Menunggu Data</option>
                  )}
                </select>
              </Col>
              <Col lg={12} className="d-flex flex-column mb-3">
                <label className="mb-2">Addon</label>
                <select
                  id="addon_id"
                  disabled={dataInput?.category_addon ? false : true}
                  onClick={() => {
                    let queryParams = `?category_id=${dataInput?.category_addon}`;
                    if (
                      dataInput?.subcategory_addon !== undefined &&
                      dataInput?.subcategory_addon !== null
                    ) {
                      queryParams += `&subcategory_id=${dataInput?.subcategory_addon}`;
                    }
                    getAddOnList(null, queryParams);
                  }}
                  onChange={handleChange}
                  className="form-select"
                >
                  <option hidden>-- Pilih Addon --</option>

                  {addOnList ? (
                    addOnList?.length > 0 ? (
                      addOnList.map((item, index) => {
                        return <option value={item.id}>{item.name}</option>;
                      })
                    ) : (
                      <option disabled>Tidak Ada Data</option>
                    )
                  ) : (
                    <option disabled>Menunggu Data</option>
                  )}
                </select>
              </Col>
              <Col lg={12} className="d-flex flex-column mb-3">
                <FormInput
                  label="Jumlah"
                  id="amount"
                  handleChange={handleChange}
                  type="text"
                />
              </Col>
              <Col lg={12} className="d-flex flex-column mb-3">
                <FormInput
                  label="Harga Satuan"
                  id="price"
                  handleChange={handleChange}
                  type="text"
                  disable={true}
                  value={
                    selectedAddOn?.price ? formatRupiah(selectedAddOn.price) : 0
                  }
                />
              </Col>
              <Col lg={12} className="d-flex flex-column mb-3">
                <FormInput
                  label="Total Harga"
                  id="price_total"
                  handleChange={handleChange}
                  type="text"
                  disable={true}
                  value={totalAddOnPrice ? formatRupiah(totalAddOnPrice) : 0}
                />
              </Col>
            </Row>
            <div className="w-100 text-end mt-4">
              <Button
                variant="secondary"
                className="me-2"
                onClick={() => setModalAddon(false)}
              >
                Tutup
              </Button>
              <Button type="submit" variant="primary">
                Simpan
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={modalDelete} onHide={() => setModalDelete(false)} center>
        <Modal.Body>
          <div className="text-center mb-5">
            <h3>Apakah Kamu Yakin Ingin Menghapus Addon?</h3>
          </div>
          <div className="w-100 text-center">
            <Button className="me-3" onClick={() => setModalDelete(false)}>
              Batal
            </Button>
            <Button variant="danger" onClick={handleDeleteAddon}>
              Hapus
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={modalEdit} onHide={() => setModalEdit(false)} center>
        <Modal.Body>
          <Form onSubmit={handleSaveEditAddon}>
            <div className="mb-3">
              <label className="mb-2">Addon</label>
              <select
                id="addon_id"
                onChange={handleChange}
                className="form-select"
                value={
                  dataAddon?.addon_id?.id
                    ? dataAddon?.addon_id?.id
                    : dataAddon?.addon_id
                }
              >
                <option hidden>-- Pilih Addon --</option>

                {addOnList ? (
                  addOnList?.length > 0 ? (
                    addOnList.map((item, index) => {
                      return <option value={item.id}>{item.name}</option>;
                    })
                  ) : (
                    <option disabled>Tidak Ada Data</option>
                  )
                ) : (
                  <option disabled>Menunggu Data</option>
                )}
              </select>
            </div>
            <div className="mb-3">
              <FormInput
                label="Jumlah"
                id="amount"
                handleChange={handleChangeAddOn}
                type="text"
                value={dataAddon?.amount}
              />
            </div>
            <div className="mb-3">
              <FormInput
                label="Harga Satuan"
                id="price"
                disable={true}
                value={formatRupiah(dataAddon?.price)}
                handleChange={handleChangeAddOn}
              />
            </div>
            <div className="mb-3">
              <FormInput
                label="Harga Total"
                id="price_total"
                disable={true}
                value={formatRupiah(totalAddOnEdit)}
                handleChange={handleChangeAddOn}
              />
            </div>
            <div className="text-end mt-4">
              <Button type="submit">Simpan</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Col>
  );
}

import { Col, Container, Image, Row } from "react-bootstrap";
import WebLayout from "../components/Layout/WebLayout";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./../styles/Calculator.module.scss";
import trash from "./../assets/trash.svg";
import arrowLeft from "./../assets/Profile/arrow-left.svg";

import { LocationServices } from "../http/LocationHttp";
import { PlaceServices } from "../http/PlaceHttp";
import moment from "moment";
import PreviewCalculator from "../components/Calculator/PreviewCalculator";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

export default function CalculatorWisata() {
  const history = useNavigate();

  const [showNav, setShowNav] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [priceTotal, setPriceTotal] = useState(0);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [listHotel, setListHotel] = useState(null);
  const [listFlight, setListFlight] = useState(null);
  const [listFlightFull, setListFlightFull] = useState(null);
  const [listTrain, setListTrain] = useState(null);
  const [listBus, setListBus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [itineraryType, setItineraryType] = useState([]);
  const [agenda, setAgenda] = useState([]);
  const [visa, setVisa] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [payload, setPayload] = useState(defaultPayload);
  const [isPreview, setIsPreview] = useState(false);
  const [rooms, setRooms] = useState(null);

  useEffect(() => {
    getLocation();
    getVisa("");
  }, []);

  // useEffect(() => {
  //   let tmp = {
  //     title: "Judul 1",
  //     category: "Wisata",
  //     guest: "10",
  //     visa: "",
  //     tourGuide: "",
  //     subTotal: "",
  //     discount: "",
  //     total: "",
  //     itinerary: [
  //       {
  //         object_model: "flight-Flight",
  //         start_date: "2024-10-20T12:30:00.000Z",
  //         end_date: "",
  //         location_id: "",
  //         origin: "1-Jakarta",
  //         destination: "1-Jakarta",
  //         object_id: "61-Garuda Indonesia - GA",
  //         child_id: "",
  //         total_item: "",
  //         ticket_id: "",
  //         total_price: "",
  //         agenda: [],
  //         sequence_number: "",
  //         price: "",
  //         total_guests: "",
  //         discount: "",
  //         ticket_list: [],
  //         room_list: [],
  //         total_room: "",
  //         seat_type: "",
  //       },
  //       {
  //         object_model: "kereta-Train",
  //         start_date: "2024-10-20T19:00:00.000Z",
  //         end_date: "",
  //         location_id: "",
  //         origin: "1-Jakarta",
  //         destination: "1-Jakarta",
  //         object_id: "2-Haramain Speedtrain",
  //         child_id: "",
  //         total_item: "",
  //         ticket_id: "",
  //         total_price: "",
  //         agenda: [],
  //         sequence_number: "",
  //         price: "",
  //         total_guests: "",
  //         discount: "",
  //         ticket_list: [],
  //         room_list: [],
  //         total_room: "",
  //         seat_type: "",
  //       },
  //       {
  //         object_model: "hotel-Hotel",
  //         start_date: "2024-10-21T14:00:00.000Z",
  //         end_date: "2024-10-22T03:00:00.000Z",
  //         location_id: "",
  //         origin: "",
  //         destination: "",
  //         object_id: "12-Anwar al Madinah Mövenpick Hotel",
  //         child_id: "",
  //         total_item: "",
  //         ticket_id: "",
  //         total_price: "",
  //         agenda: [
  //           {
  //             date_agenda: "2024-10-21",
  //             date_agenda_start: "2024-10-19T14:00:00.000Z",
  //             date_agenda_end: "2024-10-19T15:30:00.825Z",
  //             description: "tesss",
  //           },
  //         ],
  //         sequence_number: "",
  //         price: "",
  //         total_guests: "",
  //         discount: "",
  //         ticket_list: [],
  //         room_list: [],
  //         total_room: "",
  //         seat_type: "",
  //         location: "1-Jakarta",
  //       },
  //     ],
  //   };
  //   console.log(tmp);

  //   setPayload(tmp);
  // }, []);

  // useEffect(() => {
  //   console.log(itineraryType);
  // }, [itineraryType]);

  const handleChange = (e, id, type) => {
    const { value, name } = e.target;
    let splitData = value.split("-");
    let newData = [...itineraryType];
    newData[id] = { ...newData[id], [name]: value };

    setItineraryType(newData);
    if (type === "hotel") {
      setSelectedHotel(value);
      let params = `?start_date=${
        startDate
          ? moment(startDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD")
      }&end_date=${
        endDate
          ? moment(endDate).format("YYYY-MM-DD")
          : moment().add(1, "days").format("YYYY-MM-DD")
      }&adults=1`;
      getDetailListData(splitData[0], params);
    } else {
      getListData(splitData[0]);
    }
  };

  const handleChangeCalculator = (e) => {
    const { value, name } = e.target;
    const newData = { ...payload };
    newData[name] = value;
    setPayload(newData);
  };

  const selectHotel = (e) => {
    const { name, value } = e.target;
    setSelectedHotel(value);
  };

  const addAgenda = (e, idx) => {
    e.preventDefault();
    let tmp = [...itineraryType];
    if (!tmp[idx].agenda) {
      tmp[idx].agenda = []; // Initialize agenda if it doesn't exist
    }
    tmp[idx].agenda = [...tmp[idx].agenda, defaultAgenda];
    setItineraryType(tmp);
    console.log(tmp[idx].agenda);
  };

  const addItinerary = (e) => {
    e.preventDefault();
    setItineraryType([...itineraryType, defaultItinerary]);
  };

  const handleChangeAgenda = (e, i, idx, name) => {
    let tmp = [...itineraryType];
    let dt = null;
    if (name !== "description") dt = moment(e).format("DD-MM-YYYY HH:mm");

    tmp[idx].agenda[i] = {
      ...tmp[idx].agenda[i],
      [name]:
        name === "description" || name === "date_agenda" ? e.target.value : e,
    };
    setItineraryType(tmp);
  };

  const deleteAgenda = (e, i) => {
    e.preventDefault();
    const newData = [...itineraryType];
    newData.splice(i, 1);
    setAgenda(newData);
  };

  const deleteAkomodasi = (e, i) => {
    e.preventDefault();
    const newData = [...itineraryType];
    newData.splice(i, 1);
    setItineraryType(newData);
  };

  const getVisa = async (param = "") => {
    try {
      let response = await PlaceServices.visaList(param);
      let tmp = [];
      response.data.map((e, i) => {
        tmp[i] = {}; // Initialize the object at index i
        tmp[i].label = `${e.title}`;
        tmp[i].value = e.id;
      });
      setVisa(tmp);
    } catch (err) {
      console.log(err);
    }
  };

  const getDetailListData = async (id, params) => {
    try {
      let response = await PlaceServices.checkAvailability(id, params);
      setRooms(response.rooms);
    } catch (err) {
      console.log(err);
    }
  };

  const getListData = async (data) => {
    try {
      let response = await PlaceServices.placeList(data, `?limit=4`, {});
      if (response) {
        if (data === "hotel") {
          let tmp = [];
          response.message.map((e, i) => {
            tmp[i] = {}; // Initialize the object at index i
            tmp[i].label = `${e.title}`;
            tmp[i].value = e.id;
          });
          setListHotel(tmp);
        } else if (data === "bus") {
          let tmp = [];
          response.message.map((e, i) => {
            tmp[i] = {}; // Initialize the object at index i
            tmp[i].label = `${e.title} - ${e.code}`;
            tmp[i].value = e.id;
          });
          setListBus(tmp);
        } else if (data === "flight") {
          let tmp = [];
          response.message.map((e, i) => {
            tmp[i] = {}; // Initialize the object at index i
            tmp[i].label = `${e.title} - ${e.code}`;
            tmp[i].value = e.airline_id;
          });
          console.log(response.message);
          setListFlightFull(response.message);
          setListFlight(tmp);
        } else {
          let tmp = [];
          response.message.map((e, i) => {
            tmp[i] = {}; // Initialize the object at index i
            tmp[i].label = `${e.title}`;
            tmp[i].value = e.id;
          });
          setListTrain(tmp);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const selectedStartDate = (date, idx, name) => {
    setStartDate(date);
    let dt = date;
    dt = moment(dt).format("DD-MM-YYYY HH:mm");
    const newData = [...itineraryType];
    newData[idx] = { ...newData[idx], [name]: date };
    setItineraryType(newData);
  };

  const selectedEndDate = (date, idx, name) => {
    setEndDate(date);
    let dt = date;
    dt = moment(dt).format("DD-MM-YYYY HH:mm");
    const newData = [...itineraryType];
    newData[idx] = { ...newData[idx], [name]: date };
    setItineraryType(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = { ...payload };
    data.itinerary = itineraryType;
    setPayload(data);
    console.log(data);
    setIsPreview(true);
  };

  const getLocation = async () => {
    // setIsLoading(true);
    try {
      const response = await LocationServices.locationList();
      if (response) {
        let tmp = [];
        response.data.map((e, i) => {
          tmp[i] = {}; // Initialize the object at index i
          tmp[i].label = `${e.name}`;
          tmp[i].value = e.id;
        });
        setLocationList(tmp);
      }
    } catch (err) {
      // setIsLoading(false);
      console.log(err);
    }
  };

  const handleBack = () => {
    setIsPreview(false);
    history("/");
  };

  const backtoInput = () => {
    setIsPreview(false);
  };

  return (
    <WebLayout showNav={showNav}>
      <Container>
        <Row className="mb-5 justify-content-center">
          {isPreview ? (
            <>
              <PreviewCalculator payload={payload} handleBack={handleBack} />
              <div className={styles.stickyAction}>
                <div className="">
                  <div className={styles.text}>
                    Total:{" "}
                    <span className={styles.subtotalText}>Rp 1.000.000</span>
                    {"  "}
                    <span className={styles.discountText}>20%</span>
                  </div>
                  <div className={styles.totalText}>Rp 800.000</div>
                </div>
                <div className="d-flex gap-2">
                  <button
                    onClick={handleSubmit}
                    className={`${styles.btnContinue}`}
                  >
                    Lanjutkan
                  </button>
                  <button
                    className={`${styles.btnContinue} ${styles.btnContinue_outline}`}
                    onClick={backtoInput}
                  >
                    Batal
                  </button>
                </div>
              </div>
            </>
          ) : (
            <Col xs={12} lg={10}>
              <div className="mb-4">
                <div className={`d-flex flex-column mb-2`}>
                  <Image
                    onClick={handleBack}
                    className="mb-2"
                    src={arrowLeft}
                    alt="arrow-left"
                    width={24}
                  />
                  <h1 className="mb-2 mb-md-3">Kalkulator Wisata</h1>
                </div>
                <form>
                  {repetitiveInput(
                    "input",
                    "text",
                    "Judul Wisata",
                    "",
                    handleChangeCalculator,
                    "title",
                    payload.title
                  )}
                  <div className="d-flex gap-2 justify-content-between">
                    <div className="w-50">
                      {repetitiveInput(
                        "select",
                        "",
                        "Kategori",
                        category,
                        handleChangeCalculator,
                        "category",
                        payload.category
                      )}
                    </div>
                    <div className="w-50">
                      {repetitiveInput(
                        "input",
                        "text",
                        "Jumlah Peserta",
                        "",
                        handleChangeCalculator,
                        "guests",
                        payload.guests
                      )}
                    </div>
                  </div>
                  {repetitiveInput(
                    "select",
                    "",
                    "Visa",
                    visa,
                    handleChangeCalculator,
                    "visa",
                    payload.visa
                  )}
                  {repetitiveInput(
                    "select",
                    "",
                    "Tour Guide",
                    visa,
                    handleChangeCalculator,
                    "tourGuide",
                    payload.tourGuide
                  )}
                </form>
              </div>
              <div>
                <form className="mb-5">
                  {itineraryType?.length > 0 && (
                    <div className={`${styles.titleCalculator} mb-1`}>
                      Itinerary
                    </div>
                  )}
                  {itineraryType.map((elm, idx) => {
                    return (
                      <div className={styles.wrapIti}>
                        <div className={styles.orderIti}>
                          <div className="d-flex flex-column align-items-center">
                            <div className={styles.idxIti}>{idx + 1}</div>
                            <img
                              width={18}
                              height={18}
                              src={trash}
                              onClick={(e) => deleteAkomodasi(e, idx)}
                            />
                            {/* <button
                          className={styles.btnAction_reset}
                          
                        >
                          <img>
                        </button> */}
                          </div>
                        </div>
                        <div className={styles.formField}>
                          {repetitiveInput(
                            "select",
                            "",
                            "Akomodasi",
                            typeData,
                            (e) => handleChange(e, idx, ""),
                            `object_model`,
                            elm.object_model
                          )}
                          {elm.object_model.includes("flight") && (
                            <div>
                              {repetitiveInput(
                                "date",
                                "",
                                "Tanggal",
                                "",
                                (date) =>
                                  selectedStartDate(date, idx, "start_date"),
                                "start_date",
                                elm.start_date
                              )}
                              <div className="d-flex gap-2">
                                <div className="w-50">
                                  {repetitiveInput(
                                    "select",
                                    "",
                                    "From",
                                    locationList,
                                    (e) => handleChange(e, idx, ""),
                                    "origin",
                                    elm.origin
                                  )}
                                </div>
                                <div className="w-50">
                                  {repetitiveInput(
                                    "select",
                                    "",
                                    "To",
                                    locationList,
                                    (e) => handleChange(e, idx, ""),
                                    "destination",
                                    elm.destination
                                  )}
                                </div>
                              </div>

                              {repetitiveInput(
                                "select",
                                "",
                                "Flight",
                                listFlight,
                                (e) => handleChange(e, idx, ""),
                                "object_id",
                                elm.object_id
                              )}
                            </div>
                          )}
                          {elm.object_model.includes("bus") && (
                            <div>
                              {repetitiveInput(
                                "date",
                                "",
                                "Tanggal",
                                "",
                                (date) =>
                                  selectedStartDate(date, idx, "start_date"),
                                "start_date",
                                elm.start_date
                              )}
                              <div className="d-flex gap-2">
                                <div className="w-50">
                                  {repetitiveInput(
                                    "select",
                                    "",
                                    "From",
                                    locationList,
                                    (e) => handleChange(e, idx, ""),
                                    "origin",
                                    elm.origin
                                  )}
                                </div>
                                <div className="w-50">
                                  {repetitiveInput(
                                    "select",
                                    "",
                                    "To",
                                    locationList,
                                    (e) => handleChange(e, idx, ""),
                                    "destination",
                                    elm.destination
                                  )}
                                </div>
                              </div>
                              {repetitiveInput(
                                "select",
                                "",
                                "Pilih Bus",
                                listBus,
                                (e) => handleChange(e, idx, ""),
                                "object_id",
                                elm.object_id
                              )}
                            </div>
                          )}
                          {elm.object_model.includes("kereta") && (
                            <div>
                              {repetitiveInput(
                                "date",
                                "",
                                "Tanggal",
                                "",
                                (date) =>
                                  selectedStartDate(date, idx, "start_date"),
                                "start_date",
                                elm.start_date
                              )}
                              <div className="d-flex gap-2">
                                <div className="w-50">
                                  {repetitiveInput(
                                    "select",
                                    "",
                                    "From",
                                    locationList,
                                    (e) => handleChange(e, idx, ""),
                                    "origin",
                                    elm.origin
                                  )}
                                </div>
                                <div className="w-50">
                                  {repetitiveInput(
                                    "select",
                                    "",
                                    "To",
                                    locationList,
                                    (e) => handleChange(e, idx, ""),
                                    "destination",
                                    elm.destination
                                  )}
                                </div>
                              </div>
                              {repetitiveInput(
                                "select",
                                "",
                                "Train",
                                listTrain,
                                (e) => handleChange(e, idx, ""),
                                "object_id",
                                elm.object_id
                              )}
                            </div>
                          )}
                          {elm.object_model.includes("hotel") && (
                            <div>
                              {repetitiveInput(
                                "date",
                                "",
                                "Check In",
                                "",
                                (date) =>
                                  selectedStartDate(date, idx, "start_date"),
                                "start_date",
                                elm.start_date
                              )}

                              {repetitiveInput(
                                "date",
                                "",
                                "Check Out",
                                "",
                                (date) =>
                                  selectedEndDate(date, idx, "end_date"),
                                "end_date",
                                elm.end_date
                              )}
                              {repetitiveInput(
                                "select",
                                "",
                                "Location",
                                locationList,

                                (e) => handleChange(e, idx, ""),
                                "location",
                                elm.location,
                                ""
                              )}
                              {repetitiveInput(
                                "select",
                                "",
                                "Pilih Hotel",
                                listHotel,
                                (e) => handleChange(e, idx, "hotel"),
                                "object_id",
                                elm.object_id
                              )}
                              {rooms && (
                                <>
                                  {repetitiveInput(
                                    "select",
                                    "",
                                    "Pilih Room",
                                    rooms,
                                    (e) => handleChange(e, idx, ""),
                                    "room_list",
                                    elm.room_list
                                  )}
                                  {repetitiveInput(
                                    "input",
                                    "text",
                                    "Total Room",
                                    "",
                                    (e) => handleChange(e, idx, ""),
                                    "total_item",
                                    elm.total_item
                                  )}{repetitiveInput(
                                    "input",
                                    "text",
                                    "Total Harga",
                                    "",
                                    (e) => handleChange(e, idx, ""),
                                    "total_price",
                                    elm.total_price
                                  )}
                                </>
                              )}
                              {rooms && selectedHotel && (
                                <div>
                                  {elm?.agenda?.map((_, i) => {
                                    return (
                                      <>
                                        <div className="d-flex justify-content-between">
                                          <div
                                            className={`mb-2 ${styles.titleCalculator}`}
                                          >
                                            Agenda {i + 1}
                                          </div>
                                          <div
                                            onClick={(e) => deleteAgenda(e, i)}
                                          >
                                            {/* <button
                                            className={styles.btnAction_reset}
                                            onClick={(e) => deleteAgenda(e, i)}
                                          > */}
                                            <img
                                              src={trash}
                                              width={16}
                                              height={16}
                                            />
                                            {/* </button> */}
                                          </div>
                                        </div>
                                        {repetitiveInput(
                                          "input",
                                          "date",
                                          "Tanggal Agenda",
                                          "",
                                          (e) =>
                                            handleChangeAgenda(
                                              e,
                                              i,
                                              idx,
                                              "date_agenda"
                                            ),
                                          "date_agenda",
                                          _.date_agenda
                                        )}
                                        <div className="d-flex gap-2">
                                          <div className="w-50">
                                            {repetitiveInput(
                                              "hour",
                                              "date",
                                              "Mulai",
                                              "",
                                              (e) =>
                                                handleChangeAgenda(
                                                  e,
                                                  i,
                                                  idx,
                                                  "date_agenda_start"
                                                ),
                                              "date_agenda_start",
                                              _.date_agenda_start
                                            )}
                                          </div>
                                          <div className="w-50">
                                            {repetitiveInput(
                                              "hour",
                                              "date",
                                              "Selesai",
                                              "",
                                              (e) =>
                                                handleChangeAgenda(
                                                  e,
                                                  i,
                                                  idx,
                                                  "date_agenda_end"
                                                ),
                                              "date_agenda_end",
                                              _.date_agenda_end
                                            )}
                                          </div>
                                        </div>
                                        {repetitiveInput(
                                          "textarea",
                                          "",
                                          "Kegiatan",
                                          "",
                                          (e) =>
                                            handleChangeAgenda(
                                              e,
                                              i,
                                              idx,
                                              "description"
                                            ),
                                          "description",
                                          _.description
                                        )}
                                      </>
                                    );
                                  })}
                                  <div className="w-100 mb-4">
                                    {/* <div className={styles.titleCalculator}>
                                Agenda
                              </div> */}
                                    <div>
                                      <button
                                        className={styles.btnAction}
                                        onClick={(e) => addAgenda(e, idx)}
                                      >
                                        Add Agenda
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="w-100 mb-1 mt-4">
                    <div>
                      <button
                        className={styles.btnAction}
                        onClick={addItinerary}
                      >
                        Add Itenarary
                      </button>
                    </div>
                  </div>
                  {/* {selectedData && ( */}
                  <div className={styles.stickyAction}>
                    <div className="">
                      <div className={styles.text}>
                        Total:{" "}
                        <span className={styles.subtotalText}>
                          Rp 1.000.000
                        </span>
                        {"  "}
                        <span className={styles.discountText}>20%</span>
                      </div>
                      <div className={styles.totalText}>Rp 800.000</div>
                    </div>
                    <button
                      onClick={handleSubmit}
                      className={`${styles.btnContinue}`}
                    >
                      Preview
                    </button>
                  </div>
                  {/* )} */}
                </form>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </WebLayout>
  );
}

const defaultData = [
  {
    label: "Option 1",
    value: "option_1",
  },
];

const typeData = [
  {
    label: "Flight",
    value: "flight",
  },
  {
    label: "Hotel",
    value: "hotel",
  },
  {
    label: "Bus",
    value: "bus",
  },
  {
    label: "Train",
    value: "kereta",
  },
];

const defaultAgenda = {
  date_agenda: "",
  date_agenda_start: "",
  date_agenda_end: "",
  description: "",
};

const category = [
  {
    label: "City Trips",
    value: "1",
  },
  {
    label: "Ecotourism",
    value: "2",
  },
  {
    label: "Escorted Tour",
    value: "3",
  },
  {
    label: "Lingula",
    value: "4",
  },
  {
    label: "Haji",
    value: "5",
  },
  {
    label: "Tour",
    value: "6",
  },
];

const defaultPayload = {
  title: "",
  category: "",
  guests: "",
  visa: "",
  tourGuide: "",
  itinerary: [],
  subTotal: "",
  discount: "",
  total: "",
};

const defaultItinerary = {
  object_model: "",
  start_date: "",
  end_date: "",
  location_id: "",
  origin: "",
  destination: "",
  object_id: "",
  child_id: "",
  total_item: "",
  ticket_id: "",
  total_price: "",
  agenda: [],
  sequence_number: "",
  price: "",
  total_guests: "",
  discount: "",
  ticket_list: [],
  room_list: [],
  total_room: "",
  seat_type: "",
  object_id: "",
};

const repetitiveInput = (type, inputType, label, data, func, name, value) => {
  switch (type) {
    case "input":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <input
            type={inputType}
            name={name}
            onChange={func}
            value={value}
            placeholder={`Masukkan ${label}`}
          />
        </div>
      );
    case "select":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <select onChange={func} name={name} value={value}>
            <option value="" hidden>
              Pilih {label}
            </option>
            {data?.length > 0 ? (
              data?.map((_, i) => (
                <option value={`${_.value}-${_.label}`}>{_.label}</option>
              ))
            ) : (
              <option value="">Tidak Ada Data</option>
            )}
          </select>
        </div>
      );
    case "textarea":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <textarea rows={3} name={name} onChange={func}>
            {value}
          </textarea>
        </div>
      );
    case "date":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <DatePicker
            selected={value}
            onChange={func}
            timeIntervals={30}
            showTimeSelect
            timeFormat="HH:mm"
            dateFormat="dd MMMM yyyy, HH:mm"
            placeholderText={`Pilih Waktu ${label}`}
          />
        </div>
      );
    case "hour":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <DatePicker
            selected={value}
            onChange={func}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            placeholderText={`Waktu ${label}`}
          />
          {/* <DatePicker
            selected={value}
            onChange={func}
            timeIntervals={30}
            showTimeSelect
            timeFormat="HH:mm"
            dateFormat="dd MMMM yyyy, HH:mm"
            placeholderText={`Pilih Waktu ${label}`}
          /> */}
        </div>
      );
    default:
      return null;
  }
};

import { Col, Container, Row, Image } from "react-bootstrap";
import wallet1 from "./../../assets/Home/wallet.png";
import email from "./../../assets/email.svg";
import whatsapp from "./../../assets/whatsapp.svg";
import { Link } from "react-router-dom";
import styles from "./Layout.module.scss";
import logo from "./../../assets/logo512.png"

export default function Footer() {
  return (
    <div className={styles.footer}>
      <Container className={styles.footerWrap}>
        <Row>
          <Col lg={5}>
            <div className={styles.profile}>
              <Image src={logo} alt="riyuz" />
              <div className={styles.detail}>
                <div className={styles.name}>Riyuz</div>
                <div className={styles.summary}>Sudah saatnya memenuhi panggilan</div>
              </div>
            </div>
            <div className={styles.iconBox}>
              <Image src={email} alt="email" />
              <div className={styles.detail}>
                <div>Email</div>
                <div>email@email.com</div>
              </div>
            </div>
            <div className={styles.iconBox}>
              <Image src={whatsapp} alt="whatsapp" />
              <div className={styles.detail}>
                <div>Whatsapp</div>
                <div>08982394823</div>
              </div>
            </div>
          </Col>
          <Col className={styles.menuFooter} lg={3}>
            <div className={styles.heading}>Menu</div>
            <Link to="/">Home</Link>
            <Link to="/custom-package">Kalkulator Wisata</Link>
          </Col>
          <Col className={styles.menuFooter} lg={3}>
            <div className={styles.heading}>Ikuti Kami</div>
            <Link href="#">Facebook</Link>
            <Link href="#">Instagram</Link>
            <Link href="#">Twitter</Link>
          </Col>
        </Row>
      </Container>
      <div className={styles.copyright}>Copyright © 2023 Raudhah</div>
    </div>
  );
}

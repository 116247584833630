import { Col, Container, Row } from "react-bootstrap";
import HeroShotSearch from "../Search/HeroShotSearch";
import FAQ from "../../pages/FAQ";
import FlightLocation from "../Search/FlightLocation";

export default function FlightDetail(props) {
  const { detail, isLogin, checkoutPackages, handleBack, addWishlist, styles } = props;
  return (
    <>
      <HeroShotSearch
        detail={detail}
        isLogin={isLogin}
        checkoutPackages={checkoutPackages}
        handleBack={handleBack}
        addWishlist={addWishlist}
      />
      <Container>
        <Row className={styles.contentWrap}>
          <Col lg={7} className={`${styles.detail} `}>
            <FlightLocation detail={detail} />
            <FAQ detail={detail} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

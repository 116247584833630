import { useState } from "react";
import { Button, Col, Image, Spinner } from "react-bootstrap";
import styles from "./../../styles/Profile.module.scss";
import calendar from "./../../assets/Profile/calendar.svg";
import arrowLeft from "./../../assets/Profile/arrow-left.svg";
import moment from "moment";
import { formatRupiah, slugify } from "../../constant/lib";
import { Link } from "react-router-dom";
import { isMobileCheck } from "../../utils";
import Skeleton from "react-loading-skeleton";

export default function HistoryTrx(props) {
  const { isMobile, data, isLoading } = props;
  const [statusTrx, setStatusTrx] = useState("all");

  const handleFilter = (status) => {
    setStatusTrx(status);
  };

  return (
    <Col xs={12} lg={12}>
      <div className={styles.menuShow}>
        {isMobileCheck() ? (
          <h1 className="mb-4 mb-md-3">Riwayat Transaksi</h1>
        ) : (
          <h4 className="mb-4 mb-md-3">Riwayat Transaksi</h4>
        )}
        {/* <div className={styles.filterDataHistory}>
          <input className="me-3"></input>
          <input></input> */}
        {/* <div className={styles.filterHistory}>
          <div className={styles.historyMainStatus}>Status</div>
          <div
            onClick={() => handleFilter("all")}
            className={`${styles.btnFilter} ${
              statusTrx === "all" && styles.btnFilter__active
            }`}
          >
            Semua
          </div>
          <div
            onClick={() => handleFilter("done")}
            className={`${styles.btnFilter} ${
              statusTrx === "done" && styles.btnFilter__active
            }`}
          >
            Selesai
          </div>
          <div
            className={`${styles.btnFilter} ${
              statusTrx === "unpaid" && styles.btnFilter__active
            }`}
            onClick={() => handleFilter("unpaid")}
          >
            Belum Bayar
          </div>
        </div> */}
        <div className={styles.historyList}>
          {isLoading && data === null ? (
            <Skeleton count={3} style={{ marginBottom: "12px" }} height={100} />
          ) : data?.length > 0 ? (
            data.map((item, idx) => {
              return (
                <div className={styles.historyItem}>
                  <div className={styles.historyDate}>
                    <span>
                      <Image src={calendar} alt="calendar" />
                    </span>
                    {moment(item.created_at).format("DD MMMM YYYY HH:mm")}
                  </div>
                  <div className={styles.historyContainer}>
                    <div className={styles.historyLeft}>
                      <div className={styles.historyImg}></div>
                      <div className={styles.historyWrap}>
                        <div className={styles.historyStatus}>
                          {item.status}
                        </div>
                        <div className={styles.historyName}>
                          {item.service.title}
                        </div>
                        {/* <div className={styles.history}></div> */}
                      </div>
                    </div>
                    <div className={styles.historyRight}>
                      <div className={styles.historyLabel}>Total Belanja</div>
                      <div className={styles.historyPrice}>
                        {formatRupiah(item.total)}
                      </div>
                    </div>
                  </div>
                  <div className={styles.historyAction}>
                    <div className={`${styles.historyDetail} w-100`}>
                      <Link
                        to={`/detail/${slugify(item.service.title)}/${
                          item.service.id
                        }`}
                      >
                        <Button className="w-100">Detail Paket</Button>
                      </Link>
                    </div>
                    {/* <div className={styles.historySee}>
                      <Button>Bayar</Button>
                    </div> */}
                  </div>
                </div>
              );
            })
          ) : (
            <div>Tidak Ada Riwayat Transaksi</div>
          )}
        </div>
      </div>
    </Col>
  );
}

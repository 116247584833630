export default function ServiceList(props) {
  const { detail, styles } = props;
  return (
    <>
      <div id="services" className={styles.contentBox}>
        <div className={styles.heading}>Services</div>
        {detail.terms[7] ? (
          <ul className={styles.content}>
            {detail.terms[7].child.map((item, index) => {
              return <li key={index}>{item.title}</li>;
            })}
          </ul>
        ) : (
          <div className="text-center">Tidak Ada Data</div>
        )}
      </div>
    </>
  );
}

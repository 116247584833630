import { formatRupiah, slugify } from "../../constant/lib";
import styles from "./CardProject.module.scss";
import defaultPlaceholder from "./../../assets/default.png";
import moment from "moment";
import { Button, Image, Modal, Table } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { BookingServices } from "../../http/BookingHttp";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import { FlightServices } from "../../http/FlightHttp";
import { iconArrive, iconDeparture, iconTrain } from "../../constant/data";
import Skeleton from "react-loading-skeleton";

export default function TrainCard(props) {
  const { item, handleImageError, type } = props;
  const refCount = useRef(null);
  const [openSeatModal, setOpenSeatModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [currItem, setCurrItem] = useState(null);
  const [isShowTotal, setIsShowTotal] = useState(false);
  const [isSuccessAddToCart, setIsSuccessAddToCart] = useState(false);
  const navigate = useNavigate();
  const [typeUrl, setTypeUrl] = useState("hotel");
  const [countPrices, setCountPrices] = useState([]);
  const [detailData, setDetailData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingCode, setBookingCode] = useState("");

  useEffect(() => {
    if (isSuccessAddToCart) {
      // navigate(
      //   `/confirmation-booking?guest=${count}&price=${
      //     (detail.sale_price ? detail.sale_price : detail.price) * count
      //   }&title=${slugify(
      //     detail.title
      //   )}&booking_code=${bookingCode}&type=${typeUrl}${
      //     startDate
      //       ? `&start_date=${moment(startDate).format("YYYY-MM-DD")}`
      //       : ""
      //   }${endDate ? `&end_date=${moment(endDate).format("YYYY-MM-DD")}` : ""}${
      //     typeUrl === "hotel"
      //       ? `&adults=${adultGuest}&children=${childGuest}`
      //       : ""
      //   }`
      // );
      navigate(
        `/confirmation-booking?title=${slugify(detailData.title)}&departure=${
          detailData.airport_from?.name
        }&arrival=${detailData.airport_to?.name}&airline=${
          detailData.airline?.name
        }&booking_code=${bookingCode}&type=flight`
      );
    }
  }, [isSuccessAddToCart]);

  const getDetailTrain = async (id) => {
    try {
      const response = await FlightServices.getDetailKeretaUmroh(id);
      if (response) {
        setDetailData(response.data);
        // setCountPrices(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenSeat = (item, id) => {
    setSelectedData(item);
    setOpenSeatModal(!openSeatModal);
    getDetailTrain(id);
  };

  const total = countPrices.reduce((acc, item) => {
    const subtotal = parseInt(item.qty) * parseInt(item.price);
    return acc + subtotal;
  }, 0);

  useEffect(() => {
    if (detailData?.flight_seat?.length > 0) {
      const newData = [...detailData?.flight_seat].map((room) => ({
        qty: 0,
        id: room.id,
        price: room.price,
        max_passengers: room.max_passengers,
        flight_id: room.flight_id,
        seat_type: room.seat_type,
        person: room.person,
        baggage_check_in: room.baggage_check_in,
        baggage_cabin: room.baggage_cabin,
        author_id: "",
        price_html: room.price_html,
      }));
      setCountPrices(newData);
    }
  }, [detailData]);

  const countingPrice = (e, type, idx, selectedItem) => {
    e.preventDefault();
    setIsShowTotal(true);
    setCurrItem(selectedItem);
    // service_id: 20
    // service_type: flight

    const newCountPrices = [...countPrices];

    if (type === "add") {
      newCountPrices[idx].qty++;
    } else {
      if (newCountPrices[idx].qty > 0) {
        newCountPrices[idx].qty--;
      }
    }
    // if (action === "add") {
    //   newCountPrices[idx]++;
    // } else if (action === "minus" && newCountPrices[idx] > 0) {
    //   newCountPrices[idx]--;
    // }

    setCountPrices(newCountPrices);
  };

  const handleBuyNow = async () => {
    const outputObject = {};
    const filteredPayload = countPrices.filter((item) => item.qty > 0);
    filteredPayload.forEach((item, index) => {
      outputObject[`flight_seat[${index}][id]`] = item.id;
      outputObject[`flight_seat[${index}][price]`] = item.price;
      outputObject[`flight_seat[${index}][max_passengers]`] =
        item.max_passengers;
      outputObject[`flight_seat[${index}][flight_id]`] = item.flight_id;
      outputObject[`flight_seat[${index}][seat_type][id]`] = item.seat_type.id;
      outputObject[`flight_seat[${index}][seat_type][code]`] =
        item.seat_type.code;
      outputObject[`flight_seat[${index}][seat_type][name]`] =
        item.seat_type.name;
      outputObject[`flight_seat[${index}][person]`] = item.person;
      outputObject[`flight_seat[${index}][baggage_check_in]`] =
        item.baggage_check_in;
      outputObject[`flight_seat[${index}][baggage_cabin]`] = item.baggage_cabin;
      outputObject[`flight_seat[${index}][price_html]`] = item.price_html
        ? item.price_html
        : "";
      outputObject[`flight_seat[${index}][number]`] = item.qty;
      outputObject[`flight_seat[${index}][author_id]`] = "";
      outputObject[`total_price`] = total;
    });
    let payload = {
      service_id: currItem.id,
      service_type: "flight",
    };
    countPrices.total = total;
    localStorage.setItem("bookFlight", JSON.stringify(countPrices));
    setIsLoading(true);
    let finalPayload = { ...payload, ...outputObject };
    const formData = new FormData();

    // Append each key-value pair from finalPayload to the FormData object
    for (const key in finalPayload) {
      formData.append(key, finalPayload[key]);
    }
    try {
      const response = await BookingServices.postAddToCart(formData);
      // console.log(detail);
      if (response) {
        // setBookingCode(response.booking_code);
        setBookingCode(response.booking_code);
        setTypeUrl("hotel");
        setIsSuccessAddToCart(true);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setIsSuccessAddToCart(false);
    }
  };

  return (
    <>
      <div>
        <div className={styles.thumbnail}>
          {item.image ? (
            <img alt="img" src={item.image} onError={handleImageError} />
          ) : (
            <img src={defaultPlaceholder} alt="default" />
          )}
          {item.discount_percent && (
            <div className={styles.discount}>{item.discount_percent}</div>
          )}
        </div>
        <div className={styles.detail}>
          <div className={styles.wrapTitle}>
            <div className={styles.title}>
              {item?.title} | {item.code}
            </div>
          </div>
          {/* <div className="d-flex mb-3 justify-content-between">
            <div className={styles.time}>
              <img src={iconTrain} width={16} height={16} className="me-1" />
              {moment(item.departure_time).format("DD MMM YYYY HH:mm")} -
              {moment(item.arrival_time).format("DD MMM YYYY HH:mm")}
            </div>
          </div> */}
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className={styles.startFrom}>Mulai Dari</div>
              <div
                className={styles.priceFrom}
                // style={{ marginRight: 10, fontSize: 22 }}
              >
                {formatRupiah(item.min_price ?? item.price ?? item.sale_price)}
              </div>
            </div>
          </div>
          <div>
            <Button
              className={styles.selectSeat}
              onClick={() => handleOpenSeat(item.flight_seat, item.id)}
            >
              Book Kereta
            </Button>
          </div>
        </div>
      </div>
      <Modal show={openSeatModal} onHide={handleOpenSeat} size="lg" centered>
        <Modal.Header>
          <Modal.Title>
            <h5 className="mb-0">
              <b>Book Kereta</b>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {detailData ? (
              <div className="mb-3">
                {detailData?.flight_seat?.length > 0 ? (
                  <>
                    {detailData?.flight_seat?.map((item, idx) => {
                      return (
                        <div key={idx} className={styles.seatItem}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <div className={styles.seatName}>
                                {item.seat_type?.name}
                              </div>
                              <div className={styles.maxPassengers}>
                                Maksimal {item?.max_passengers} Penumpang
                              </div>
                            </div>
                            <div className={styles.badge}>
                              {/* Bagasi */}
                              {item.person}
                            </div>
                          </div>
                          <div className={styles.separator}></div>
                          <div className="d-flex gap-2 justify-content-between align-items-center mb-2">
                            <div className="w-50">
                              <div className={styles.labelModal}>
                                Bagage Cabin
                              </div>
                              <div className={styles.valueModal}>
                                {item.baggage_cabin}
                              </div>
                            </div>
                            <div className="w-50">
                              <div className={styles.labelModal}>
                                Bagage Check In
                              </div>
                              <div className={styles.valueModal}>
                                {item.baggage_check_in}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex gap-2 justify-content-between align-items-center mb-2">
                            <div>
                              <div className={styles.labelModal}>Harga</div>
                              <div className={styles.valueModal_price}>
                                {formatRupiah(item.price)}
                              </div>
                            </div>
                            <div>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "12px" }}
                              >
                                <Button
                                  onClick={(e) =>
                                    countingPrice(e, "minus", idx, item)
                                  }
                                >
                                  -
                                </Button>
                                <div className={styles.qty}>
                                  {countPrices[idx]?.qty || 0}
                                </div>
                                <Button
                                  onClick={(e) =>
                                    countingPrice(e, "add", idx, item)
                                  }
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* <div className="table-responsive">
                      <Table className="mb-2">
                        <thead>
                          <tr>
                            <th>Info</th>
                            <th>Bagasi</th>
                            <th>Check In</th>
                            <th>Cabin</th>
                            <th>Harga</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {detailData?.flight_seat?.map((item, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <div>
                                    <b>Tipe Seat: {item.seat_type?.name}</b>
                                    <div>
                                      Penumpang Maks : {item?.max_passengers}
                                    </div>
                                  </div>
                                </td>
                                <td>{item.person}</td>
                                <td>{item.baggage_check_in}</td>
                                <td>{item.baggage_cabin}</td>
                                <td>{formatRupiah(item.price)}</td>
                                <td>
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ gap: "12px" }}
                                  >
                                    <Button
                                      onClick={(e) =>
                                        countingPrice(e, "minus", idx, item)
                                      }
                                    >
                                      -
                                    </Button>
                                    <div>{countPrices[idx]?.qty || 0}</div>
                                    <Button
                                      onClick={(e) =>
                                        countingPrice(e, "add", idx, item)
                                      }
                                    >
                                      +
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                    <div className="d-flex flex-row justify-content-end align-items-center mt-5">
                      <div className="me-3">
                        Total: <b>{formatRupiah(total)}</b>
                      </div>
                      <div>
                        <Button onClick={handleBuyNow}>Beli Sekarang</Button>
                      </div>
                    </div> */}
                    <div className="d-flex flex-column  mt-3">
                      <div className="me-3 mb-4 text-start">
                        Total: <b>{formatRupiah(total)}</b>
                      </div>
                      <div className="d-flex justify-content-between gap-3 w-100">
                        <div className="w-50">
                          <Button
                            onClick={() => setOpenSeatModal(false)}
                            className={styles.btnBuy_outline}
                          >
                            Batal
                          </Button>
                        </div>
                        <div className="w-50">
                          <Button
                            onClick={handleBuyNow}
                            className={styles.btnBuy}
                          >
                            Beli Sekarang
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="text-center">Tidak ada seat tersedia</div>
                )}
              </div>
            ) : (
              <>
                <Skeleton height={180} style={{ marginBottom: "12px" }} />
                <Skeleton height={180} />
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

import { Col, Container, Image, Row } from "react-bootstrap";
import HeroShotSearch from "../Search/HeroShotSearch";
import defaultPlaceholder from "../../assets/default.png";
import { formatRupiah } from "../../constant/lib";
import profile from "../../assets/profile.svg";
import Description from "../Search/Description";
import { Link, useNavigate } from "react-router-dom";
import clock from "./../../assets/clock.svg";
import location from "./../../assets/location.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NavigationDetail from "../DetailCatalogue/NavigationDetail";
import { isMobileCheck } from "../../utils";
import Policy from "../DetailCatalogue/Policy";
import Gallery from "../DetailCatalogue/Gallery";
import ServiceList from "../DetailCatalogue/ServiceList";
import Facilities from "../DetailCatalogue/Facilities";

export default function HotelDetail(props) {
  const {
    detail,
    isLogin,
    checkoutPackages,
    handleBack,
    addWishlist,
    styles,
    roomList,
    handleAddGuest,
    onChange,
    startDate,
    endDate,
    checkRoom,
    adultGuest,
    childGuest,
  } = props;
  const navigate = useNavigate();

  return (
    <>
      <HeroShotSearch
        detail={detail}
        isLogin={isLogin}
        checkoutPackages={checkoutPackages}
        handleBack={handleBack}
        addWishlist={addWishlist}
      />
      <Container>
        <Row className={styles.contentWrap}>
          {!isMobileCheck() && (
            <NavigationDetail type="hotel" styles={styles} data={detail} />
          )}
          <Col lg={7} className={`${styles.detail} `}>
            <Description detail={detail} />

            <Facilities detail={detail} styles={styles} />
            <ServiceList detail={detail} styles={styles} />
            <div id="rooms" className={styles.contentBox}>
              <div className={styles.heading}>Room Hotel</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 60 + "px",
                }}
              >
                {roomList?.rooms?.length > 0 ? (
                  roomList?.rooms && (
                    <>
                      <Row className={`${styles.listWrap}`}>
                        {roomList.rooms.map((item, index) => {
                          return (
                            <Col lg={12} md={6} sm={12} xs={12} key={index}>
                              {/* <CardProject item={item} type={type} /> */}
                              <Link
                                to={checkoutPackages}
                                className={`${styles.listItem} ${styles.listItem_available}`}
                              >
                                <div className={styles.detailItem}>
                                  <div className="me-3">
                                    <img
                                      src={defaultPlaceholder}
                                      alt="img"
                                      width={75}
                                    />
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div className={styles.thumbnail}>
                                      {item.discount_percent && (
                                        <div className={styles.discount}>
                                          {item.discount_percent}
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                        className={styles.wrapTitle}
                                      >
                                        <div
                                          style={{
                                            overflow: "hidden",
                                            display: "-webkit-box",
                                            WebkitLineClamp: 2,
                                            lineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                            fontSize: 14 + "px",
                                            fontWeight: 500,
                                            color: "#000000",
                                          }}
                                          className={styles.title}
                                        >
                                          {item?.title}
                                        </div>
                                      </div>
                                      {
                                        <>
                                          {item.sale_price ? (
                                            item?.price ? (
                                              <div
                                                style={{
                                                  color: "#00854c",
                                                  fontSize: 16 + "px",
                                                  fontWeight: 800,
                                                }}
                                                className={styles.originalPrice}
                                              >
                                                {formatRupiah(item?.price)}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            ""
                                          )}
                                          {item.sale_price ? (
                                            <div
                                              style={{
                                                color: "#00854c",
                                                fontSize: 16 + "px",
                                                fontWeight: 800,
                                              }}
                                              className={styles.salePrice}
                                            >
                                              {formatRupiah(item.sale_price)}
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                color: "#00854c",
                                                fontSize: 16 + "px",
                                                fontWeight: 800,
                                              }}
                                              className={styles.salePrice}
                                            >
                                              {formatRupiah(item?.price)}
                                            </div>
                                          )}
                                        </>
                                      }
                                      <div className={styles.iconRoom}>
                                        <div>
                                          <span className="me-2">
                                            <img
                                              src="https://cdn-icons-png.flaticon.com/512/494/494970.png"
                                              alt="img"
                                              width={24}
                                            />
                                          </span>
                                          <span>{item.beds_html}</span>
                                        </div>
                                        <div>
                                          <span className="me-2">
                                            <Image src={profile} alt="" />
                                          </span>
                                          {item.adults_html}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  )
                ) : (
                  <div className="text-center">Tidak Ada Data</div>
                )}
              </div>
            </div>
            <Gallery detail={detail} styles={styles} />
            <Policy detail={detail} styles={styles} />
          </Col>
          <Col lg={4} className={`${styles.summary} offset-lg-1`}>
            <div className={styles.summaryWrap}>
              <div className={`${styles.iconBox} mb-2 mb-md-4`}>
                <div className={styles.titleIconBox}>
                  <Image src={clock} alt="" />
                  Check-In / Check-Out
                </div>
                <div className={styles.detailIconBox}>
                  {detail.check_in_time} {"/"} {detail.check_out_time}
                </div>
              </div>
              <div className={`${styles.iconBox} mb-2 mb-md-4`}>
                <div className={styles.titleIconBox}>
                  <Image src={location} alt="" />
                  Lokasi Keberangkatan
                </div>
                <div className={styles.detailIconBox}>
                  <>
                    {detail.location?.name
                      ? detail.location?.name
                      : detail?.location}
                  </>
                </div>
              </div>
              <hr class="solid"></hr>
              {isLogin ? (
                <>
                  <div className={`${styles.quantityWrap} mt-4 mb-5`}>
                    <div className={`${styles.quantityOrder} mb-2 mb-md-4`}>
                      <div className={`${styles.titleQuantityOrder} mb-3`}>
                        <Image src={profile} alt="" />
                        Tamu Dewasa
                      </div>
                      <div className={styles.detailQuantityOrder}>
                        <div
                          className={`${styles.btnInput} me-3`}
                          onClick={() => handleAddGuest("decrement", "adult")}
                        >
                          -
                        </div>
                        <input className={styles.qty} value={adultGuest} />
                        <div
                          className={`${styles.btnInput} ms-3`}
                          onClick={() => handleAddGuest("increment", "adult")}
                        >
                          +
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.quantityOrder} mb-4`}>
                      <div className={`${styles.titleQuantityOrder} mb-3`}>
                        <Image src={profile} alt="" />
                        Tamu Anak-Anak
                      </div>
                      <div className={styles.detailQuantityOrder}>
                        <div
                          className={`${styles.btnInput} me-3`}
                          onClick={() => handleAddGuest("decrement", "child")}
                        >
                          -
                        </div>
                        <input className={styles.qty} value={childGuest} />
                        <div
                          className={`${styles.btnInput} ms-3`}
                          onClick={() => handleAddGuest("increment", "child")}
                        >
                          +
                        </div>
                      </div>
                    </div>
                    <div className={` mb-4`}>
                      <div className={`${styles.titleQuantityOrder} mb-3`}>
                        Check In & Check Out
                      </div>
                      <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      onClick={(e) => checkRoom(e, detail.id)}
                      className={`btnYellow mb-4 w-100 ${styles.addToCart}`}
                    >
                      Cek Ketersediaan Room
                    </div>
                  </div>
                </>
              ) : (
                <div
                  onClick={() => navigate("/login")}
                  className={`btnYellow btnYellow__outline mt-4 mb-2 w-100 ${styles.addToCart}`}
                >
                  Login untuk Memesan
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

import { Image } from "react-bootstrap";
import styles from "./../../styles/DetailProduct.module.scss";
import cartBlack from "./../../assets/Home/cartBlack.svg";
import add from "./../../assets/add.svg";
import minus from "./../../assets/minus.svg";

export default function StickyAction(props) {
  const {
    count,
    handleAdd,
    checkoutPackages,
    addToCart,
    currUrl,
    checkAvailability,
  } = props;
  const type = currUrl.package;
  console.log(type);
  return (
    <div className={`${styles.stickyNav} ${styles.stickyNav__checkout}`}>
      {type === "hotel" ? (
        <div
          onClick={checkAvailability}
          className={`${styles.button__buyHotel} ${styles.button}`}
        >
          Cek Ketersediaan Hotel
        </div>
      ) : type === "visa" ? (
        <div className="d-flex w-100">
          <div className="w-100">
            <div
              // onClick={addToCart}
              className={`${styles.button__checkout} w-100 ${styles.button}`}
            >
              Checkout
            </div>
          </div>
          <div></div>
        </div>
      ) : (
        <>
          <div className="d-flex">
            <div className={styles.detailQuantityOrder}>
              <div>
                <div style={{ fontSize: 10, marginBottom: "4px" }}>
                  Jumlah Guest
                </div>
                <div className="d-flex">
                  <div
                    className={`${styles.btnInput} me-2`}
                    onClick={() => handleAdd("decrement")}
                  >
                    <Image width={16} height={16} src={minus} />
                  </div>
                  <input
                    className={`${styles.qty} ${styles.qty__mobile}`}
                    value={count}
                  />
                  <div
                    className={`${styles.btnInput} ms-2`}
                    onClick={() => handleAdd("increment")}
                  >
                    <Image width={16} height={16} src={add} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={checkoutPackages}
            className={`${styles.button__buyPackages} ${styles.button}`}
          >
            Beli Paket
          </div>
          {/* <div
            onClick={addToCart}
            className={`${styles.button__addCart} ${styles.button}`}
          >
            <Image src={cartBlack} width={24} height={24} alt="cart" />
          </div> */}
        </>
      )}
    </div>
  );
}

export default function Policy(props) {
  const { detail, styles } = props;
  return (
    <div id="policy" className={styles.contentBox}>
      <div className={styles.heading}>Policy</div>
      <div className={styles.content}>
        {detail.policy?.length > 0 ? (
          detail.policy?.map((item, index) => {
            return (
              <div key={index} className="mb-3">
                <div>
                  <b>{item.title}</b>
                </div>
                {item.content.split("\n").map((line, index) => (
                  <div key={index} className="mb-1">
                    {line}
                  </div>
                ))}
              </div>
            );
          })
        ) : (
          <div className="text-center">Tidak Ada Data</div>
        )}
      </div>
    </div>
  );
}

import styles from "./../../styles/Home.module.scss";
import directBox from "./../../assets/Home/directbox.svg";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function CustomPackages() {
  return (
    <div className={styles.banner}>
      <div className={styles.detailWrap}>
        <Image src={directBox} alt="directbox" />
        <div className={styles.detail}>
          <h2>Butuh Kalkulator Wisata?</h2>
          <div className={styles.text}>
            Kalkulator Wisata untuk memudahkan anda dalam menghitung biaya.
          </div>
        </div>
      </div>
      <div className={styles.bannerBtn}>
        <Link to="/calculator" >
          Hitung Biaya
        </Link>
      </div>
    </div>
  );
}

import { Carousel, Col, Container, Row } from "react-bootstrap";
import styles from "./../../styles/Home.module.scss";
import homeBg1 from "./../../assets/Home/bg.png";
import homeBg2 from "./../../assets/Home/tour.jpg";
import homeBg3 from "./../../assets/Home/visa.jpg";

export default function HeroShot() {
  const sliderHome = [
    {
      img: homeBg1,
      title: "Sudah Saatnya Memenuhi Panggilan",
      desc: "Pergi ke tanah suci semudah, sepraktis, dan senyaman itu!",
    },
    {
      img: homeBg2,
      title: "Memperoleh Visa Perjalanan Umroh",
      desc: "Pergi ke tanah suci semudah, sepraktis, dan senyaman itu!",
    },
    {
      img: homeBg3,
      title: "Memperoleh Visa Perjalanan Umroh",
      desc: "Pergi ke tanah suci semudah, sepraktis, dan senyaman itu!",
    },
  ];
  return (
    <div className="d-none d-md-block">
      <Carousel indicators={false}>
        {sliderHome.map((item, index) => (
          <Carousel.Item key={index}>
            <div
              style={{
                backgroundImage: `url(${item.img})`,
              }}
              className={styles.heroShot}
            >
              {/* <div className={styles.overlay}></div> */}
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className={styles.wrapText}>
                      <h1 className={styles.mainHeader}>{item.title}</h1>
                      <h2 className={styles.subHeader}>{item.desc}</h2>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

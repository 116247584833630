import { riyuzAPI, riyuzAPIV2 } from "../constant/api";

export const PlaceServices = {
  placeList(place, param) {
    return riyuzAPI.get(`${place}/list${param}`);
    // return riyuzAPIV2.get(`${place}${param}`);
  },
  busList(param) {
    return riyuzAPI.get(`umroh-buses${param}`);
  },
  tourList(param) {
    return riyuzAPI.get(`tour/search${param ? param : ""}`);
  },
  flightList(param) {
    return riyuzAPI.get(`flight/search${param ? param : ""}`);
  },
  cateringList(param) {
    return riyuzAPI.get(`catering/search${param ? param : ""}`);
  },
  busList(param) {
    return riyuzAPI.get(`bus/search${param ? param : ""}`);
  },
  trainList(param) {
    return riyuzAPI.get(`train/search${param ? param : ""}`);
  },
  hotelList(param) {
    return riyuzAPI.get(`hotel/search${param ? param : ""}`);
  },
  visaList(param) {
    return riyuzAPI.get(`visa/search${param ? param : ""}`);
  },
  roomHotelList(id, param) {
    return riyuzAPI.get(`hotel/availability/${id}${param}`);
  },
  checkAvailability(id, param) {
    return riyuzAPI.get(`hotel/availability/${id}${param}`);
  },
  tourFilter() {
    return riyuzAPI.get(`tour/filters`);
  },
  formSearchFilter() {
    return riyuzAPI.get(`tour/form-search`);
  },
  flightFilter() {
    return riyuzAPI.get(`flight/filters`);
  },
  busFilter() {
    return riyuzAPI.get(`bus/filters`);
  },
  trainFilter() {
    return riyuzAPI.get(`train/filters`);
  },
  hotelFilter() {
    return riyuzAPI.get(`hotel/filters`);
  },
  visaFilter() {
    return riyuzAPI.get(`visa/filters`);
  },
  tourById(id) {
    return riyuzAPI.get(`tour/detail/${id}`);
  },
  flightById(id) {
    return riyuzAPI.get(`flight/detail/${id}`);
  },
  hotelById(id) {
    return riyuzAPI.get(`hotel/detail/${id}`);
  },
  busById(id) {
    return riyuzAPI.get(`bus/detail/${id}`);
  },
  visaById(id) {
    return riyuzAPI.get(`visa/detail/${id}`);
  },
};

import { isMobileCheck } from "../../../utils";

export const TitleMenu = (props) => {
  const { title, styles } = props;
  return (
    <div className={styles.menuShow}>
      {isMobileCheck() ? (
        <h1 className="mb-4 mb-md-2">{title}</h1>
      ) : (
        <h4 className="mb-4 mb-md-2">{title}</h4>
      )}
    </div>
  );
};

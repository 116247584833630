import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { sizeBajuKoko } from "../../../constant/variable";
import { Col, Row } from "react-bootstrap";
import InputCommon from "../../../common/utils";
export const PhysicalInformation = (props) => {
  const {
    styles,
    setFormWizardStep,
    formWizardStep,
    handleChangeFamilyData,
    payloadDataCreateUser,
  } = props;
  return (
    <div className={styles.collapsibleHeader}>
      <div
        className="d-flex justify-content-between align-items-center"
        onClick={() => setFormWizardStep(formWizardStep == 3 ? null : 3)}
      >
        <b>Informasi Fisik</b>
        {formWizardStep == 3 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </div>
      {formWizardStep == 3 && (
        <div className={styles.collapsibleContent}>
          <Row className="my-4">
            <Col lg={6}>
              <InputCommon
                type="common"
                label="Hidung"
                placeholder="Jenis Hidung Jamaah"
                id="hidung"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.hidung}
                // value={
                //   type === "edit"
                //     ? editData?.hidung !== "null"
                //       ? editData?.hidung
                //       : undefined
                //     : undefined
                // }
              />
            </Col>
            <Col lg={6}>
              <InputCommon
                type="common"
                label="Alis"
                placeholder="Alis Jamaah"
                id="alis"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.alis}
                // value={
                //   type === "edit"
                //     ? editData?.alis !== "null"
                //       ? editData?.alis
                //       : undefined
                //     : undefined
                // }
              />
            </Col>
            <Col xs={6}>
              <InputCommon
                type="number"
                label="Tinggi (Cm)"
                placeholder="Tinggi Jamaah"
                id="tinggi"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.tinggi}
                // value={
                //   type === "edit"
                //     ? editData?.tinggi !== "null"
                //       ? editData?.tinggi
                //       : undefined
                //     : undefined
                // }
              />
            </Col>
            <Col xs={6}>
              <InputCommon
                type="number"
                label="Berat (Kg)"
                placeholder="Berat Jamaah"
                id="berat"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.berat}
                // value={
                //   type === "edit"
                //     ? editData?.berat !== "null"
                //       ? editData?.berat
                //       : undefined
                //     : undefined
                // }
              />
            </Col>
            <Col lg={6}>
              <InputCommon
                type="common"
                label="Rambut"
                placeholder="Rambut Jamaah"
                id="rambut"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.rambut}
                // value={
                //   type === "edit"
                //     ? editData?.rambut !== "null"
                //       ? editData?.rambut
                //       : undefined
                //     : undefined
                // }
              />
            </Col>
            <Col lg={6}>
              <InputCommon
                type="common"
                label="Bentuk Wajah"
                placeholder="Bentuk Wajah Jamaah"
                id="muka"
                onChange={(e) => handleChangeFamilyData(e)}
                value={payloadDataCreateUser?.muka}
                // value={
                //   type === "edit"
                //     ? editData?.muka !== "null"
                //       ? editData?.muka
                //       : undefined
                //     : undefined
                // }
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default function Facilities(props) {
  const { detail, styles } = props;
  return (
    <>
      <div id="facilities" className={styles.contentBox}>
        <div className={styles.heading}>Fasilitas</div>
        {detail.terms[6] ? (
          <ul className={styles.content}>
            {detail.terms[6].child.map((item, index) => {
              return <li key={index}>{item.title}</li>;
            })}
          </ul>
        ) : (
          <div className="text-center">Tidak Ada Data</div>
        )}
        {/* <div
      className={styles.content}
      dangerouslySetInnerHTML={{ __html: detail.terms }}
    /> */}
      </div>
    </>
  );
}

import { Accordion } from "react-bootstrap";
import styles from "./../styles/DetailProduct.module.scss";

export default function FAQ(props) {
  const { detail } = props;
  return (
    <div id="faq" className={styles.contentBox}>
      <div className={styles.heading}>FAQs</div>
      {detail.faqs?.length > 0 ? (
        <Accordion defaultActiveKey="0">
          {detail.faqs.map((item, idx) => {
            return (
              <Accordion.Item eventKey={idx}>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body>{item.content}</Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      ) : (
        <div>Tidak ada FAQ</div>
      )}
    </div>
  );
}

export default function Gallery(props) {
  const { detail, styles } = props;
  return (
    <div id="gallery" className={styles.contentBox}>
      <div className={styles.heading}>Gallery</div>
      <div className={styles.content}>
        <div className="row">
          {detail.gallery?.length > 0 ? (
            detail.gallery?.map((item, index) => {
              return (
                item && (
                  <div className="col-md-4" key={index}>
                    <img src={item} alt="img" className="w-100 mb-3" />
                  </div>
                )
              );
            })
          ) : (
            <div className="text-center">Tidak Ada Data</div>
          )}
        </div>
      </div>
    </div>
  );
}

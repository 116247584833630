import { Col } from "react-bootstrap";
import { formatRupiah } from "../../constant/lib";

export default function NavigationDetail(props) {
  const { type, styles, data } = props;

  const navigate = (elmnt) => {
    const element = document.getElementById(elmnt);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      window.scrollBy(0, -100); // Adjust scroll position by -100px for padding
    }
  };

  console.log(data, "<=-");

  const renderElement = (type) => {
    switch (type) {
      case "hotel":
        return (
          <Col lg={12}>
            <div className={styles.contentBoxOutline}>
              <div className="d-flex gap-3">
                <div
                  className={styles.itemBox}
                  onClick={() => navigate("info")}
                >
                  Info
                </div>
                <div
                  className={styles.itemBox}
                  onClick={() => navigate("facilities")}
                >
                  Fasilitas
                </div>
                <div
                  className={styles.itemBox}
                  onClick={() => navigate("services")}
                >
                  Services
                </div>
                <div
                  className={styles.itemBox}
                  onClick={() => navigate("rooms")}
                >
                  Rooms
                </div>
                <div
                  className={styles.itemBox}
                  onClick={() => navigate("gallery")}
                >
                  Gallery
                </div>
                <div
                  className={styles.itemBox}
                  onClick={() => navigate("policy")}
                >
                  Policy
                </div>
               
              </div>
              <div className="d-flex gap-2">
                <div className={styles.itemBoxSub}>Mulai Dari</div>
                <div className={styles.itemBoxPrice}>{formatRupiah(data.price)}</div>
              </div>
            </div>
          </Col>
        );
      case "tour":
        return <Col lg={12}>
        <div className={styles.contentBoxOutline}>
          <div className="d-flex gap-3">
            <div
              className={styles.itemBox}
              onClick={() => navigate("info")}
            >
              Info
            </div>
            <div
              className={styles.itemBox}
              onClick={() => navigate("facilities")}
            >
              Fasilitas
            </div>
            <div
              className={styles.itemBox}
              onClick={() => navigate("departure")}
            >
              Lokasi Keberangkatan
            </div>
            <div
              className={styles.itemBox}
              onClick={() => navigate("itinerary")}
            >
              Itinerary
            </div>
            <div
              className={styles.itemBox}
              onClick={() => navigate("faq")}
            >
              FAQ
            </div>
           
          </div>
          <div className="d-flex gap-2">
            <div className={styles.itemBoxSub}>Mulai Dari</div>
            <div className={styles.itemBoxPrice}>{formatRupiah(data.price)}</div>
          </div>
        </div>
      </Col>;
      case "flight":
        return <></>;
    }
  };
  return renderElement(type);
}

import { Row } from "react-bootstrap";
import SlideCardMobile from "./SlideCardMobile";
import PopularPackageDesktop from "./PopularPackageDesktop";
import { isMobileCheck } from "../../utils";
import styles from "./../../styles/Home.module.scss";
import { Link } from "react-router-dom";

export default function PopularPackages(props) {
  const { data, isLoading } = props;
  // if (data.length > 0) {
  return (
    <div className={styles.popular}>
      <div className={styles.heading}>
        <h2>Paket Terpopuler</h2>
        <label>Paket Haji dan Umrah terpopuler dan terlaris saat ini</label>
      </div>
      {isMobileCheck() ? (
        <Row className="justify-content-between w-100">
          <SlideCardMobile data={data} type="tour" isLoading={isLoading} />
        </Row>
      ) : (
        <PopularPackageDesktop data={data} />
      )}
      <Link to="/search?type=hajj" className={styles.seeAll}>
        Lihat Semua
      </Link>
    </div>
  );
  // }
}
